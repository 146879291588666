import { Component } from 'react'
import styled from '@emotion/styled'

interface Props {
  options: { active: boolean; value: string; label: string }[]
  onOptionSelected: (option: string) => void
  style?: React.CSSProperties
  variant?: 'primary' | 'secondary'
}

export class Tab extends Component<Props> {
  render() {
    const { options, onOptionSelected, style, variant = 'primary' } = this.props
    if (!options || options.length === 0) {
      return <></>
    }

    return (
      <OptionsContainer style={style}>
        {options.map((option) => (
          <OptionButton
            variant={variant}
            key={`option-${option.value}`}
            active={option.active}
            onClick={() => onOptionSelected(option.value)}
          >
            {option.label}
          </OptionButton>
        ))}
      </OptionsContainer>
    )
  }
}

const OptionsContainer = styled.div`
  height: 77px;
  background: #ffffff;
  border-radius: 100px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const OptionButton = styled.div<{ variant: 'primary' | 'secondary'; active: boolean }>`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  width: 150px;
  height: 57px;
  border-radius: 100px;
  background: ${({ active, variant, theme }) => (active ? theme[`${variant}Bg`] : 'white')};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ variant, active }) => variant === 'secondary' && active && `color: white;`}
`
