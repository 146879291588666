import { Component } from 'react'
import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'
import { MotionStyle } from 'framer-motion'
import { TmrStatus } from 'stylewhere/api'

interface Props {
  counter?: number
  title?: string
  style?: MotionStyle
  status?: 'error' | 'warning' | 'ignored'
  onClick?: () => void
}

export class SmallTextCounter extends Component<Props> {
  render() {
    const { counter = 0, title, style, status, onClick } = this.props
    return (
      <Container style={style} onClick={onClick}>
        <Box pl={10}>{title}</Box>
        <SmallCounter status={status} flex center>
          {counter}
        </SmallCounter>
      </Container>
    )
  }
}

const Container = styled(Box)`
  padding: 5px;
  padding-top: 0px;
  background-color: white;
  border-radius: 10px;
  height: 100px;
  width: 100px;
  line-height: 36px;
  font-size: 18px;
  font-weight: bold;
  flex: 1;
`

const SmallCounter = styled(Box)<{ status?: TmrStatus }>`
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  align-self: center;
  background-color: ${({ status }) => {
    switch (status) {
      case 'error':
        return '#FF8474'
      case 'warning':
        return '#FFEA67'
      case 'ignored':
        return '#DAE6ED'
      default:
        return '#F0F0F0'
    }
  }};
`
