import Base from 'core/pages/SimpleItemList/SimpleItemListReading'
import {
  AntennaButton,
  Box,
  Button,
  OperationReadingCounters,
  OperationReadingList,
  Page,
  Spacer,
  TagCounter,
} from 'stylewhere/components'
import { SimpleItemListExtensions } from 'stylewhere/extensions'
import {
  AppStore,
  getDataFromSchema,
  OperationReadingProps,
  OperationReadingState,
  RfidReader,
} from 'stylewhere/shared'
import { EnumerationValue, EnumerationValues, SimpleItemList } from 'stylewhere/api'
import { showToast, showToastError } from 'stylewhere/shared/utils'
import { T, __ } from 'stylewhere/shared/i18n'
import styled from '@emotion/styled'

type Packaging = {
  width: string
  height: string
  depth: string
  tare: string
  description: string
}

interface State extends OperationReadingState {
  packaging: EnumerationValue<Packaging>
  weight?: number
}

export default class SimpleItemListReading extends Base<OperationReadingProps, State> {
  async componentDidMount() {
    await super.componentDidMount()
    if (this.operation.code === 'create-parcel-gate' && this.props.location.state?.formData) {
      const result = await EnumerationValues.search<EnumerationValue<Packaging>>({
        enumerationTypeCode: 'PACKAGING',
        equalCodes: this.props.location.state.formData.attributes.packagingCode,
      })
      if (result.content.length === 1) {
        this.setState({ packaging: result.content[0] })
      }
    }
  }

  showConfirmButton = () => {
    const { formData } = this.state
    if (formData?.attributes?.enableAutoPrint) {
      return null
    }
    return super.showConfirmButton()
  }

  onConfirm = async () => {
    const { items, formData, weight } = this.state
    try {
      const confirmData = getDataFromSchema(formData, this.formSchema)
      await SimpleItemListExtensions.beforeConfirm(this.operation, confirmData, items)
      if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing worksation place')

      if (this.operation.code === 'create-parcel-gate') {
        confirmData.attributes.userAction = 'CONFIRM'
        confirmData.attributes.weight = weight?.toString() ?? 0
      }

      const confirmResult = await SimpleItemList.save({
        ...confirmData,
        operationId: this.operation.id,
        operationPlaceId: AppStore.defaultWorkstation.placeId,
        itemIds: items.flatMap(({ item }) => (item?.id ? item.id : [])),
      })
      await SimpleItemListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      this.goBack()
    } catch (err) {
      showToastError(err)
    }
  }

  onClear = async () => {
    if (this.operation.code === 'create-parcel-gate') {
      const { items, formData, weight } = this.state
      try {
        const confirmData = getDataFromSchema(formData, this.formSchema)
        await SimpleItemListExtensions.beforeConfirm(this.operation, confirmData, items)
        if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing worksation place')
        confirmData.attributes.userAction = 'CLEAR'
        confirmData.attributes.weight = weight?.toString() ?? 0
        const confirmResult = await SimpleItemList.save({
          ...confirmData,
          operationId: this.operation.id,
          operationPlaceId: AppStore.defaultWorkstation.placeId,
          itemIds: items.flatMap(({ item }) => (item?.id ? item.id : [])),
        })
        await SimpleItemListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.generic_success_canceled, { code: this.operation.description }),
          status: 'success',
        })
      } catch (err) {
        showToastError(err)
      }
    }

    RfidReader.clear()
    this.setState({ items: [] })
  }

  getWeight = async () => {
    try {
      const res = await RfidReader.getWeight()
      if (res.error) throw new Error('Errore durante la lettura')
      this.setState({ weight: res.weight })
    } catch (error) {
      showToastError(error)
    }
  }

  renderLocationAssignment() {
    // Location assignment non ha la modale di modifica (prop header di Page)
    const { items, formData, loading } = this.state
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
          },
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={items.length} />
            <AntennaButton onClear={this.onClear} hideClear={items.length === 0} />
            <Spacer />
            <OperationReadingCounters operation={this.operation} items={items} />
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList
            removeItemCallback={this.removeItem}
            // actionItemCallback={() => alert('')}
            extension={SimpleItemListExtensions}
            items={items}
            operation={this.operation}
          />
        </Page.Content>
      </Page>
    )
  }

  renderCreateParcelGate() {
    // Location assignment non ha la modale di modifica (prop header di Page)
    const { items, formData, loading, packaging } = this.state
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
          },
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={items.length} />
            <AntennaButton onClear={this.onClear} hideClear={items.length === 0} />
            <Spacer />
            <OperationReadingCounters operation={this.operation} items={items} />
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList
            removeItemCallback={this.removeItem}
            // actionItemCallback={() => alert('')}
            extension={SimpleItemListExtensions}
            items={items}
            operation={this.operation}
          />
          <BalanceCard row>
            <Box flex>
              <Box row style={{ width: '100%' }}>
                <Box flex row>
                  {__(T.create_parcel_gate.width)}
                  <strong>: {packaging?.attributes?.width}</strong>
                </Box>
                <Box flex row>
                  {__(T.create_parcel_gate.height)}
                  <strong>: {packaging?.attributes?.height}</strong>
                </Box>
              </Box>
              <Box row>
                <Box flex row>
                  <>
                    {__(T.create_parcel_gate.depth)}
                    <strong>: {packaging?.attributes?.depth}</strong>
                  </>
                </Box>
                <Box flex row>
                  {__(T.create_parcel_gate.tare)}
                  <strong>: {packaging?.attributes?.tare}</strong>
                </Box>
              </Box>
              <Box flex row style={{ marginTop: 10, wordBreak: 'normal' }}>
                <p>
                  {__(T.create_parcel_gate.description)}: <b>{packaging?.attributes?.description ?? '--'}</b>
                </p>
              </Box>
            </Box>
            <Box row center>
              <Button size="medium" style={{ height: 55 }} title="Pesa collo" onClick={this.getWeight} />
              <WeightBox center>
                <b>{this.state.weight ?? 0} kg</b>
              </WeightBox>
            </Box>
          </BalanceCard>
        </Page.Content>
      </Page>
    )
  }

  render() {
    if (this.operation.code === 'location-assignment') return this.renderLocationAssignment()
    if (this.operation.code === 'create-parcel-gate') return this.renderCreateParcelGate()
    return super.render()
  }
}

const BalanceCard = styled(Box)`
  background-color: white;
  border-radius: 15px;
  margin-top: 14px;
  padding: 14px;
`

const WeightBox = styled(Box)`
  background: #f0f0f0;
  border-radius: 8px;
  margin-left: 20px;
  height: 55px;
  padding: 20px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 100px;
`
