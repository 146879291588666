import { ShippingExtensions as Base } from 'core/extensions/ShippingExtensions'
import { Places, ShippingParcel, TmrPlace } from 'stylewhere/api'
import { FormSchema, FormSchemaData, getFieldValue, OperationConfig, ShippingOperationConfig } from 'stylewhere/shared'

export class ShippingExtensions extends Base {
  static formSchema(operation: ShippingOperationConfig, additionalData?: { parcel?: ShippingParcel }): FormSchema {
    const schema = Base.formSchema(operation)
    if (operation.code === 'inbound-warehouse' && additionalData?.parcel !== undefined) {
      schema.forEach((field) => {
        //cerca automaticamente i campi dentro il parcel e nel caso prefilla i defaultvalue
        const defaultValue = getFieldValue(additionalData.parcel, field)
        if (defaultValue) field.defaultValue = defaultValue
      })
    }
    return schema
  }

  static async beforeConfirm<O extends any[]>(
    operation: OperationConfig,
    data: FormSchemaData,
    objects: O
  ): Promise<boolean> {
    if (operation.code === 'inbound-warehouse') {
      const place = await Places.get<TmrPlace>(data.asn.originPlaceId)
      data.attributes.unloadPlaceCode = place.code
      return true
    }
    return super.beforeConfirm(operation, data, objects)
  }
}
