import { Products } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { FormSchema, FormSchemaData, jsonToFormSchema } from 'stylewhere/shared'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { Extensions } from './Extensions'

export class EncodingExtensions extends Extensions {
  static formSchema(operation: EncodingOperationConfig): FormSchema {
    if (operation.formSchema && Object.keys(operation.formSchema).length > 0)
      return jsonToFormSchema(operation.formSchema as any)

    return [
      {
        type: 'text',
        name: 'product.code',
        label: __(T.misc.upc),
        required: true,
      },
    ]
  }

  static async getItemConfiguration(operation: EncodingOperationConfig, data: FormSchemaData) {
    const product = await Products.get(data.product.code)
    return product
  }
}
