import { merge as _merge } from 'lodash'
import en from 'core/translations/en'

export default _merge(en, {
  titles: {
    outbound_bthp: 'Outbound BTHP Certificate',
  },
  simple_parcel_list: {
    to_read: 'To Read',
    ok: 'Ok',
    force: 'Force',
    force_parcel: 'Force parcel {{code}}',
    force_parcel_desc: 'Insert user PIN to force parcel',
    forced: 'Forced',
    unexpected: 'Unexpected',
    remove: 'Remove',
    invalid_pin: 'User PIN invalid',
  },
  create_parcel_gate: {
    width: 'Width',
    height: 'Height',
    depth: 'Depth',
    tare: 'Tare',
    description: 'Description',
  },
  misc: {
    typelogy: 'Typelogy',
  },
  serverError: {
    WRONG_ITEM_SUPPLIER: 'Some read articles cannot be taken over by the supplier.',
    QUANTITY_MISMATCH_ERROR: 'Quantities entered do not correspond to the quantities detected.',
    ZONE_MISMATCH_ERROR: 'Zone entered does not correspond to the zone detected.',
  },
})
