import styled from '@emotion/styled'
import { Box, Button } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'

interface Props {
  onCancel?: () => void
  onSubmit?: React.FormEventHandler<HTMLFormElement>
  submitText: string
  style?: React.CSSProperties
  columns?: boolean
  loading?: boolean
  flex?: boolean
}

export const Form: React.FC<Props> = ({ flex, columns, submitText, style, children, onCancel, onSubmit, loading }) => (
  <FormStyle flex={flex} columns={columns} style={style} onSubmit={onSubmit}>
    <FieldsWrapper columns={columns}>{children}</FieldsWrapper>
    {onCancel && (
      <Box row style={{ marginTop: 60 }}>
        <Box flex>
          <Button variant="default" type="button" onClick={onCancel}>
            {__(T.misc.cancel)}
          </Button>
        </Box>
        <div style={{ width: 20 }} />
        <Box flex>
          <Button loading={loading} type="submit">
            {submitText}
          </Button>
        </Box>
      </Box>
    )}
    {!onCancel && (
      <Box style={{ marginTop: 60 }}>
        <Button loading={loading} type="submit">
          {submitText}
        </Button>
      </Box>
    )}
  </FormStyle>
)

const FieldsWrapper = styled.div<{ columns?: boolean }>`
  display: ${({ columns }) => (columns ? 'grid' : 'block')};
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px 30px;
  justify-content: center;
`

const FormStyle = styled.form<{ columns?: boolean; flex?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ flex, columns }) => !flex && `width: ${columns ? 640 : 400}px;`}
  ${({ flex }) => flex && `flex: 1;`}
`
