import { Component } from 'react'
import styled from '@emotion/styled'
import {
  Page,
  LeftHeader,
  KeyValueRow,
  Spacer,
  Box,
  UserInfoModal,
  SettingsModal,
  Icons,
  DashboardOperationsButtons,
} from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { TmrWorkstation } from 'stylewhere/api'
import { AppStore, Router } from 'stylewhere/shared'

interface State {
  userModalVisible: boolean
  settingsModalVisible: boolean
}
export default class Dashboard extends Component<{}, State> {
  state: State = {
    userModalVisible: false,
    settingsModalVisible: false,
  }

  setuserModalVisible = (userModalVisible: boolean) => this.setState({ userModalVisible })

  setsettingsModalVisible = (settingsModalVisible: boolean) => this.setState({ settingsModalVisible })

  selectDefaultWorkstation = async (workstation: TmrWorkstation) => {
    await AppStore.saveDefaultWorkstation(workstation)
    Router.refresh()
  }

  render() {
    const { userModalVisible, settingsModalVisible } = this.state

    return (
      <Page title="Dashboard" header={null}>
        <Box flex row>
          <LeftHeader>
            <KeyValueRow
              onClick={() => this.setuserModalVisible(!userModalVisible)}
              label={__(T.misc.user)}
              value={AppStore.loggedUser?.username}
              image={<Icons.Info />}
            />
            <Spacer />
            <KeyValueRow
              label={__(T.misc.workstation)}
              value={AppStore.defaultWorkstation?.code ?? '---'}
              optionModal={{
                onSelect: (item) => this.selectDefaultWorkstation(item[0]),
                field: 'code',
                options: AppStore.workstations ?? [],
                selected: [AppStore.defaultWorkstation],
                title: __(T.titles.select_workstation),
                searchable: true,
              }}
            />
          </LeftHeader>
          <RightContainer>
            <DashView>
              <DashboardOperationsButtons />
            </DashView>
          </RightContainer>
        </Box>
        <UserInfoModal
          user={AppStore.loggedUser}
          onClose={() => this.setuserModalVisible(false)}
          visible={userModalVisible}
        />
        <SettingsModal onClose={() => this.setsettingsModalVisible(false)} visible={settingsModalVisible} />
      </Page>
    )
  }
}

const DashView = styled(Box)`
  max-width: 700px;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: start;
  padding-top: 50px;
  flex-basis: 150px;
  flex-shrink: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const RightContainer = styled.div`
  display: flex;
  flex: 1.5;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
