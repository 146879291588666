import { DecodedItem, GroupedItemsByProduct, ShippingParcel, TmrTag } from 'stylewhere/api'
import { FormSchemaData, FormSchemaRouteProps, OperationConfig, RfidReader } from 'stylewhere/shared'
import { showToastError } from 'stylewhere/utils'

/** Stato base condiviso da tutte le route di Operation Reading */
export type OperationReadingState = {
  items: DecodedItem[]
  loading: boolean
  formData: FormSchemaData
  tags?: TmrTag[]
}

/** Prop condivise da tutte le route di Operation Reading */
export type OperationReadingProps<State = OperationReadingState> = FormSchemaRouteProps<{ opCode: string }, State>

export class OperationReadingProvider {
  static async init(
    operation: OperationConfig,
    locationState: Partial<OperationReadingState>,
    backCallback: () => void,
    customFunction?: () => void
  ) {
    if (!locationState.formData) {
      backCallback()
      return
    }

    try {
      await RfidReader.initialize()
      customFunction?.()
      // Aggiungi tags eventualmente letti durante la start
      locationState.tags?.forEach((tag) => RfidReader.emulateTag(tag))
      operation.autostartAntenna && RfidReader.start()
    } catch (err) {
      showToastError(err)
      backCallback()
    }
  }

  static checkItemStatus(operation: OperationConfig, item: DecodedItem<string>) {
    if (!item.item || item.statuses?.some((status) => operation.itemStatuses.ignore.includes(status))) {
      if (!item.item) {
        item.item = {} as any //Forzo item non a sistema item
      }
      item.item!.status = 'ignored'
      return item
    }

    if (item.statuses?.some((status) => operation.itemStatuses.warning.includes(status)) && item?.item)
      item.item.status = 'warning'
    if (item.statuses?.some((status) => operation.itemStatuses.error.includes(status)) && item?.item)
      item.item.status = 'error'

    return item
  }

  static async processDecodedItems<T extends DecodedItem[] = DecodedItem[]>(
    operation: OperationConfig,
    itemMapFromReader: { [tagCode: string]: DecodedItem },
    items: T,
    formData: FormSchemaData,
    extensions?: any
  ) {
    const processedItems: T = items

    await Promise.all(
      Object.keys(itemMapFromReader).map(async (tagCode) => {
        const decodedItem = itemMapFromReader[tagCode]
        decodedItem.identifierCode = tagCode
        let processedItem = OperationReadingProvider.checkItemStatus(operation, decodedItem)

        if (!processedItem.item || processedItem.item.status === 'ignored') {
          /**
           * @todo Gestire gli item non a sistema in base allo status (decodable/non decodable)
           * per ora li ignoriamo e basta
           */
          processedItems.push(processedItem)
        } else if (items.find((itm) => itm.item !== null && itm.item?.id === processedItem.item?.id) === undefined) {
          if (extensions) processedItem = await extensions.processItem(operation, formData ?? {}, processedItem)

          if (processedItem && processedItem.item !== null) {
            processedItems.push(processedItem)
          }
        }
        return undefined
      })
    )

    return processedItems
  }

  static removeItem<T extends DecodedItem>(decodedItem: T, items: T[]) {
    const { item, identifierCode } = decodedItem
    if (!item && !identifierCode) return items

    const idx = items.findIndex((i) => item?.id === i.item?.id || identifierCode === i?.identifierCode)
    if (idx > -1) {
      items.splice(idx, 1)
      if (item) RfidReader.removeTags(item.identifiers.map(({ code }) => code))
      else if (identifierCode) RfidReader.removeTags([identifierCode])
    }
    return items
  }

  static groupItems(items: DecodedItem[], operation: OperationConfig, extension?: any, parcel?: ShippingParcel) {
    if (operation?.readingsDisplayMode === 'groupedByProduct') {
      const rowsByProduct: GroupedItemsByProduct[] = []

      if (parcel) {
        if (parcel.checklist) {
          parcel.checklist.checklistItems.forEach(({ productCode }) => {
            const product = parcel.products[productCode]
            if (!product) {
              throw new Error(`Checklist product ${productCode} missing from parcel`)
            }
            let row = rowsByProduct.find((p) => p.product.id === product.id)
            if (!row) {
              row = {
                product,
                detected: 0,
                expected: 0,
                unexpected: 0,
                items: [],
                status: undefined,
              }
              rowsByProduct.push(row)
            }
            row.expected++
          })
        }
      }

      items.forEach((decodedItem) => {
        if (!decodedItem.item || decodedItem.item.status === 'ignored') return
        const { product } = decodedItem.item
        let row = rowsByProduct.find((p) => p.product.id === product.id)

        if (!row) {
          row = {
            product,
            detected: 0,
            expected: 0,
            unexpected: 0,
            items: [],
            status: undefined,
          }
          rowsByProduct.push(row)
        }

        row.detected++
        row.items.push(decodedItem)

        // Stato
        if (decodedItem.item.status === 'error') {
          row.status = 'error'
        } else if (decodedItem.item.status === 'warning' && row.status !== 'error') {
          row.status = 'warning'
        }
      })

      return (
        rowsByProduct
          // Processa le righe con l'extension (se ritorna null la riga deve essere saltata)
          .map((row) => extension.processRow(operation, row))
          .filter((row): row is GroupedItemsByProduct => row !== null && row)
      )
    }

    if (operation.readingsDisplayMode === 'item') {
      return items.filter((itm) => itm.item?.status !== 'ignored')
    }

    throw new Error(`Unsupported operation readingsDisplayMode ${operation.readingsDisplayMode}`)
  }
}
