import { TmrBaseResource, TmrItem, TmrProduct, ProductInfoField, TmrSimpleAttributesMap } from 'stylewhere/api'

export class AttributeMappings extends TmrBaseResource {
  static endpoint = '/profile/api/v1/attributeMappings'

  static getProductAttribute = async (product: TmrProduct, fields: ProductInfoField[], exclude: string[]) => {
    let cols: ProductInfoField[] = []
    if (product && product.attributes) {
      const mapping = await AttributeMappings.search<TmrSimpleAttributesMap>({ entity: 'product' })
      if (mapping && mapping.content && mapping.content.length > 0) {
        for (let r = 0; r < fields.length; r++) {
          cols.push(fields[r])
        }
        for (let r = 0; r < mapping.content.length; r++) {
          if (exclude.indexOf(mapping.content[r].code) === -1) {
            cols.push({
              path: 'attributes.' + mapping.content[r].code + '.value',
              label: mapping.content[r].code,
            })
          }
        }
      } else {
        cols = fields
      }
    } else {
      cols = fields
    }
    return cols
  }

  static getItemAttribute = async (item: TmrItem) => {
    const mappingItem = await AttributeMappings.search<TmrSimpleAttributesMap>({ entity: 'item' })
    const attrs: TmrSimpleAttributesMap[] = []
    if (mappingItem && mappingItem.content && mappingItem.content.length > 0 && item.attributes) {
      for (let r = 0; r < mappingItem.content.length; r++) {
        attrs.push({
          label: mappingItem.content[r].code,
          value: item.attributes[mappingItem.content[r].code]
            ? item.attributes[mappingItem.content[r].code].toString()
            : '---',
        })
      }
    }
    return attrs
  }
}
