import {
  api,
  DecodedItemSorting,
  responseErrorCheck,
  Sorting,
  TmrBaseResource,
  TmrSimpleAttributesMap,
} from 'stylewhere/api'
import { AppStore } from 'stylewhere/shared'

export interface SortingsDecodePayload {
  sortingId: string
  operationId: string
  itemIdentifierCode: string
  originZoneId: string
  attributes?: TmrSimpleAttributesMap
}

export interface SortingsDeletePayload {
  sortingId: string
  operationId: string
}

export interface SortingsClosePayload {
  sortingId: string
  operationId: string
  attributes?: TmrSimpleAttributesMap
}

export class Sortings extends TmrBaseResource {
  static endpoint = 'logistics/api/v1/sortings'
  static endpointOperation = 'logistics/api/v1/operations/sorting'

  static async getById(id: string, requestConfig?) {
    const result = await api.get<Sorting>(`${this.endpoint}/${id}`, requestConfig)
    return responseErrorCheck(result)
  }

  static async decode(decodePayload: SortingsDecodePayload) {
    if (decodePayload?.attributes) {
      decodePayload.attributes.workstationCode = AppStore.defaultWorkstation!.code
    } else {
      decodePayload.attributes = { workstationCode: AppStore.defaultWorkstation!.code }
    }
    const res = await api.post<DecodedItemSorting>(`${this.endpointOperation}/read`, decodePayload)
    return responseErrorCheck(res)
  }

  static async delete(deletePayload: SortingsDeletePayload) {
    const res = await api.post<DecodedItemSorting>(`${this.endpointOperation}/delete`, deletePayload)
    return responseErrorCheck(res)
  }

  static async close(closePayload: SortingsClosePayload) {
    if (closePayload?.attributes) {
      closePayload.attributes.workstationCode = AppStore.defaultWorkstation!.code
    } else {
      closePayload.attributes = { workstationCode: AppStore.defaultWorkstation!.code }
    }
    const res = await api.post<DecodedItemSorting>(`${this.endpointOperation}/close`, closePayload)
    return responseErrorCheck(res)
  }

  static async createFromPicking<T>(pickingIds: string[], operationId: string, formData?: any) {
    const res = await api.post<T>(`${this.endpointOperation}/createFromPicking`, {
      ...formData,
      pickingIds,
      operationId,
      operationPlaceId: AppStore.defaultWorkstation!.placeId,
    })
    return responseErrorCheck(res)
  }
}
