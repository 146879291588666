import styled from '@emotion/styled'
import { Box, Spacer } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  detected: number
  expected?: number
  unexpected?: number
  error?: boolean
  onPress?: () => void
}

export function TagCounter({ unexpected, error, detected, expected, onPress }: Props) {
  let fontSize: string | undefined
  const countersLength = expected ? String(expected).length + String(detected).length : String(detected).length
  if (countersLength > 5) fontSize = '80px'
  if (countersLength > 7) fontSize = '70px'

  return (
    <>
      <Container
        detected={detected}
        error={error}
        unexpected={unexpected}
        expected={expected}
        center
        flex={0}
        onClick={onPress}
      >
        <SubTitle>{__(T.misc.total_reads)}</SubTitle>
        <Counter fontSize={fontSize}>
          {detected}
          {!!expected && `/${expected}`}
        </Counter>
      </Container>
      <Spacer />
    </>
  )
}

const Counter = styled.div<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => {
    return fontSize ?? '100px'
  }};
  font-weight: bold;
  line-height: 124px;
`

const Container = styled(Box)<{ error?: boolean; detected: number; expected?: number; unexpected?: number }>`
  min-height: 220px;
  border-radius: 15px;
  background-color: ${({ error, detected, expected, unexpected }) => {
    if (error) return '#FF8474'
    if (expected) {
      if (detected > expected || !!unexpected) {
        return '#F2B57D'
      }
      if (detected < expected) {
        return '#FFFFFF'
      }
      return '#75EBA8'
    }

    return '#FFFFFF'
  }};
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 22px;
`
