import styled from '@emotion/styled'
import { ShippingParcel } from 'stylewhere/api'
import { Box as ChakraBox } from '@chakra-ui/react'
import { Box, Button, GroupedItemRow, GroupedListRow, Icons, List, Spacer } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { SimpleParcelListOperationConfig } from 'stylewhere/shared'

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  operation: SimpleParcelListOperationConfig
  parcels: ShippingParcel[]
  loading?: boolean
  removeParcel?: (parcel: ShippingParcel) => void
}

export function GroupedParcelList({ parcels, removeParcel, loading = false }: Props) {
  const rowRender = (parcel: ShippingParcel) => {
    return (
      <GroupedListRow pv={16} pl={8} pr={16} style={{ height: 120 }}>
        <Box p={8} center>
          <Icons.Rfid width={48} />
        </Box>
        <Box justify="center" pl={8} flex style={{ overflow: 'auto' }}>
          <Code>
            {parcel.code} x {parcel.parcelEntryQuantity}
          </Code>
        </Box>
        <Box row center>
          {parcel.__detected && (
            <>
              <Button
                onClick={removeParcel ? () => removeParcel(parcel) : undefined}
                variant="secondary"
                size="small"
                circle
              >
                <Icons.Delete width={24} height={24} />
              </Button>
              <Spacer />
            </>
          )}
          {!parcel.__detected && <ToRead>{__(T.simple_parcel_list.to_read)}</ToRead>}
          {parcel.__detected && parcel.__unexpected && <Unexpected>{__(T.simple_parcel_list.unexpected)}</Unexpected>}
          {parcel.__detected && !parcel.__unexpected && parcel.__forced && <Ok>{__(T.simple_parcel_list.forced)}</Ok>}
          {parcel.__detected && !parcel.__unexpected && !parcel.__forced && <Ok>{__(T.simple_parcel_list.ok)}</Ok>}
        </Box>
      </GroupedListRow>
    )
  }

  if (loading) {
    return (
      <>
        <GroupedItemRow.Skeleton />
        <GroupedItemRow.Skeleton />
      </>
    )
  }

  const rows = parcels.sort((parcel: ShippingParcel) => {
    if (parcel.__unexpected) return -1
    if (parcel.__detected) return 1
    return 0
  })

  return (
    <List
      data={rows}
      emptyMessage={<span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.press_start_to_read)}</span>}
      rowRender={rowRender}
    />
  )
}

const Code = styled.div`
  font-weight: bold;
  font-size: 28px;
  white-space: nowrap;
`

const Status = styled(ChakraBox)`
  font-weight: bold;
  font-size: 40px;
  border-radius: 8px;
  padding: 8px 20px;
`

const Unexpected = styled(Status)`
  background: #ff8474;
`

const ToRead = styled(Status)`
  background: #f0f0f0;
`

const Ok = styled(Status)`
  background: #75eba8;
`
