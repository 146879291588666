import { useEffect, useState } from 'react'
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useDebouncedCallback } from 'use-debounce'
import { Box, Icons, Input, Spacer } from 'stylewhere/components'
import { TextFormSchemaField } from 'stylewhere/shared'
// import { api } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { EnumerationValue, EnumerationValues } from 'stylewhere/api'

interface Props<Value = any> {
  disabled?: boolean
  index: number
  value: Value
  defaultValue: Value
  onChange: (value: any) => void
  field: TextFormSchemaField
}

export const CreateParcelGatePackagingCodeField: React.FC<Props> = ({
  index,
  field,
  defaultValue,
  value,
  onChange,
  disabled,
}) => {
  const [popoverData, setPopoverData] = useState<{
    width: string
    height: string
    depth: string
    tare: string
    description: string
  }>()
  const Icon = field.icon && Icons[field.icon]
  const debouncedFetch = useDebouncedCallback(
    async () => {
      const result = await EnumerationValues.search<EnumerationValue>({
        enumerationTypeCode: 'PACKAGING',
        equalCodes: value,
      })
      if (result.content.length > 0) {
        const packaging = result.content[0]
        const { width, height, depth, tare } = packaging.attributes
        setPopoverData(packaging ? { width, height, depth, tare, description: packaging.description } : undefined)
      }
    },
    400,
    { leading: true }
  )

  useEffect(() => {
    setPopoverData(undefined)
    if (value) {
      debouncedFetch()
    }
  }, [value, debouncedFetch])

  return (
    <Container>
      <Spacer />
      <Input
        key={`input${index.toString()}`}
        defaultValue={defaultValue}
        required={field.required ?? false}
        onChange={onChange}
        value={value}
        id={field.name}
        type={field.type}
        placeholder={field.secondaryLabel}
        startFocus={field.focus}
        image={Icon && <Icon />}
        label={field.label}
        disabled={field.disabled ?? disabled}
        minLength={field.minlength}
        maxLength={field.maxlength}
        debounce={200}
      />

      <Popover
        isLazy
        isOpen={!!popoverData}
        placement="right"
        offset={[57, 30]}
        preventOverflow={false}
        autoFocus={false}
        matchWidth
        arrowSize={16}
        arrowPadding={24}
      >
        <PopoverTrigger>
          <StyledTrigger>&nbsp;</StyledTrigger>
        </PopoverTrigger>
        <PopoverContent borderWidth={0} borderRadius={10} width="100%" boxShadow="base">
          <PopoverArrow />
          <PopoverBody>
            <Box flex row>
              <div style={{ flex: '50%' }}>
                {__(T.create_parcel_gate.width)}: <strong>{popoverData?.width}</strong>
              </div>
              <div style={{ flex: '50%' }}>
                {__(T.create_parcel_gate.height)}: <strong>{popoverData?.height}</strong>
              </div>
            </Box>
            <Box flex row>
              <div style={{ flex: '50%' }}>
                {__(T.create_parcel_gate.depth)}: <strong>{popoverData?.depth}</strong>
              </div>
              <div style={{ flex: '50%' }}>
                {__(T.create_parcel_gate.tare)}: <strong>{popoverData?.tare}</strong>
              </div>
            </Box>
            <div style={{ marginTop: 10 }}>
              {__(T.create_parcel_gate.description)}: <strong>{popoverData?.description}</strong>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const StyledTrigger = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`
