import { useState } from 'react'
import styled from '@emotion/styled'
import { Spinner } from '@chakra-ui/react'

export type ButtonVariant = 'primary' | 'secondary' | 'default' | 'error' | 'warning' | 'ignore' | 'plain'
const colors = {
  primary: '#ceff00',
  secondary: '#333333',
  default: '#DCDCDC',
  ignore: '#EDEDED',
  warning: '#FFEFC6',
  error: '#FFD4CB',
}

interface Props {
  title?: string
  variant?: ButtonVariant
  size?: 'default' | 'small' | 'medium'
  onClick?: () => void
  type?: string
  style?: React.CSSProperties
  loading?: boolean
  disabled?: boolean
  circle?: boolean
}

export const Button: React.FC<Props> = ({ title, onClick, children, loading, disabled = false, ...restProps }) => {
  const [internalLoading, setinternalLoading] = useState(false)
  const onPrePress = async () => {
    if (internalLoading) return
    if (!onClick) return

    try {
      setinternalLoading(true)
      await onClick()
      setinternalLoading(false)
    } catch (error) {
      //ignore
    }
  }

  const load = internalLoading || loading

  return (
    <ButtonStyle disabled={disabled || load} onClick={onPrePress} {...(restProps as any)}>
      {!load && <>{title ?? children}</>}
      {load && <Spinner />}
      {/* {load && <span>{__(T.misc.loading)}</span>} */}
    </ButtonStyle>
  )
}

const ButtonStyle = styled.button<Props>`
  position: relative;
  ${({ size, circle }) => {
    switch (size) {
      case 'small':
        return `padding: ${circle ? '0' : '0 11px'}; font-size: 17px; min-height: 36px; width: ${
          circle ? '36px' : 'auto'
        };`
      case 'medium':
        return `padding: ${circle ? '0' : '12px 20px'}; font-size: 18px; min-height: 45px; width: ${
          circle ? '45px' : 'auto'
        };`
      default:
        return `padding: ${circle ? '0' : '0 30px'}; font-size: 24px; min-height: 65px; width: ${
          circle ? '65px' : 'auto'
        };`
    }
  }};
  ${({ style }) => {
    if (style?.height) return `min-height: unset;`
    return ''
  }}
  ${({ circle }) => (circle ? `display: flex; justify-content: center; align-items: center;` : '')}
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  background: ${({ variant, theme }) => {
    const bgs = {
      primary: theme.primaryBg,
      secondary: theme.secondaryBg,
      ignore: `linear-gradient(180deg, ${colors.ignore} 60.77%, ${colors.ignore} 60.79%, ${colors.ignore} 229.23%)`,
      warning: `linear-gradient(180deg, ${colors.warning} 60.77%, ${colors.warning} 60.79%, ${colors.warning} 229.23%)`,
      error: `linear-gradient(180deg, ${colors.error} 60.77%, ${colors.error} 60.79%, ${colors.error} 229.23%)`,
      plain: 'none',
      default: `${colors.default}`,
    }
    return bgs[variant ?? 'primary']
  }};
  color: ${(props) => (props.variant === 'secondary' ? 'white' : 'rgba(51,51,51,1)')};
  ${({ variant = 'primary' }) => {
    if (variant === 'primary') return `box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 -1px 4px rgba(0, 0, 0, 0.1);`
    if (variant === 'secondary')
      return `box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15), inset 0 -1px 4px rgba(0, 0, 0, 0.1);`
    return ''
  }};
  border-color: ${(props) => (props.variant === 'secondary' ? 'transparent' : 'transparent')};
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  border-radius: ${(props) => (props.circle ? '100%' : '10px')};
  line-height: 1;
  :disabled {
    opacity: 0.5;
  }
  :focus {
    outline: ${(props) => (props.variant === 'secondary' ? 'transparent' : 'transparent')};
  }
`
