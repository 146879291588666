import { Component } from 'react'
import { Box, EncodingForm, Page } from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import { FormSchemaData, OperationReadingProps, RemoteOperation, Router } from 'stylewhere/shared'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { showToastError } from 'stylewhere/shared/utils'

interface State {
  loading: false
}

export default class EncodingEncode extends Component<OperationReadingProps<State>, State> {
  state: State = {
    loading: false,
  }

  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<EncodingOperationConfig>(this.matchParams.opCode)

  onSubmit = async (data: FormSchemaData) => {
    try {
      console.log(await EncodingExtensions.getItemConfiguration(this.operation, data))
    } catch (error) {
      showToastError(error)
    }
  }

  render() {
    const { loading } = this.state
    return (
      <Page title={this.operation.description} loading={loading} enableEmulation>
        <Page.Sidebar width={500}>
          <EncodingForm operation={this.operation} onSubmit={this.onSubmit} />
        </Page.Sidebar>
        <Page.Content>
          <Box></Box>
        </Page.Content>
      </Page>
    )
  }
}
