import { SimpleItemListExtensions as Base } from 'core/extensions/SimpleItemListExtensions'
import { DecodedItem, SimpleItemList } from 'stylewhere/api'
import {
  AppStore,
  FormSchema,
  FormSchemaData,
  getDataFromSchema,
  SimpleItemListOperationConfig,
} from 'stylewhere/shared'
import { showToastError } from 'stylewhere/utils'
import { CreateParcelGatePackagingCodeField } from 'stylewhere/components'

export class SimpleItemListExtensions extends Base {
  static formSchema(operation: SimpleItemListOperationConfig) {
    const formSchema = super.formSchema(operation)
    if (operation.code === 'create-parcel-gate') {
      const packagingCodeField = formSchema.find(({ name }) => name === 'attributes.packagingCode')
      if (packagingCodeField === undefined) {
        throw new Error('Missing field attributes.packagingCode in schema for operation create-parcel-gate')
      }
      packagingCodeField.customRender = (props) => <CreateParcelGatePackagingCodeField {...props} />
    }
    return formSchema
  }

  static async processItem(
    operation: SimpleItemListOperationConfig,
    formData: FormSchemaData<{ attributes?: { enableAutoPrint?: boolean } }>,
    item: DecodedItem
  ): Promise<DecodedItem | null> {
    if (formData.attributes?.enableAutoPrint || operation.confirmOnItemRead) {
      const schema = SimpleItemListExtensions.formSchema(operation)
      const transferData = getDataFromSchema(formData, schema)
      transferData.attributes.singleRead = true
      try {
        await SimpleItemList.save({
          ...transferData,
          operationId: operation.id,
          operationPlaceId: AppStore.defaultWorkstation?.placeId ?? '',
          itemIds: [item.item?.id],
        })
      } catch (error) {
        showToastError({ message: 'Error in single read confirmation', name: 'Error' })
      }
    }
    return super.processItem(operation, formData, item)
  }
}
