import { Component } from 'react'
import styled from '@emotion/styled'
import { Icons, Box, Button, ItemInfoModal, StatusChip, Image } from 'stylewhere/components'
import { DecodedItem, Products, TmrItem } from 'stylewhere/api'
import { OperationConfig } from 'stylewhere/shared'
import { MotionStyle } from 'framer-motion'
import { closeModal, openModal } from 'stylewhere/shared/utils'

interface Props<OC extends OperationConfig> {
  operation?: OC
  decodedItem: DecodedItem
  unexpected?: number
  onRemoveItem?: (item: DecodedItem) => void
  onActionItem?: (item: DecodedItem<string>) => void
}

interface State {
  modalVisible: boolean
}

export class GroupedItemRow<OC extends OperationConfig> extends Component<Props<OC>, State> {
  static Skeleton = styled(Box)``

  roundedLabel = (label: string, value?: string, style?: MotionStyle) => (
    <Box
      row
      mr={15}
      style={{
        backgroundColor: '#EDEDED',
        borderRadius: 5,
        ...style,
      }}
      pv={5}
      ph={10}
    >
      <SmallLabel>{label.toUpperCase()}</SmallLabel>
      <SmallValue>{value ?? ' ---'}</SmallValue>
    </Box>
  )

  renderUnknownItemRow = () => {
    const { decodedItem, onRemoveItem } = this.props
    return (
      <>
        <Row row height={120} center>
          <Box flex ph={16}>
            <ReadIdentifierCode>{decodedItem.identifierCode ?? 'Identifier N/A'}</ReadIdentifierCode>
          </Box>
          {!!onRemoveItem && (
            <Box m={8}>
              <Button onClick={() => onRemoveItem(decodedItem)} variant="secondary" size="small" circle>
                <Icons.Delete width={24} height={24} />
              </Button>
            </Box>
          )}
        </Row>
      </>
    )
  }

  showItemModal = () =>
    openModal({
      id: 'item-modal',
      modal: (
        <ItemInfoModal visible item={this.props.decodedItem.item! as any} onClose={() => closeModal('item-modal')} />
      ),
    })

  renderItemRow = () => {
    const { decodedItem, onActionItem } = this.props
    const { product } = decodedItem.item! as TmrItem
    return (
      <>
        <Row row onClick={this.showItemModal}>
          {product.code && (
            <Box center mh={30}>
              {/* <Icons.Rfid style={{ width: 50 }} /> */}
              <Image src={Products.getImageUrl(product)} width={100} height={100} />
            </Box>
          )}
          <Box flex mr={25}>
            <Box mv={25}>
              <Box>
                {decodedItem.item?.identifiers
                  .filter((idf) => idf.code !== decodedItem.identifierCode && idf.role === 'serial')
                  .map((identifier) => (
                    <ReadIdentifierCode>{identifier.code}</ReadIdentifierCode>
                  ))}
                <IdentifierCode>{decodedItem.identifierCode}</IdentifierCode>
              </Box>
              <Box bgGrey pv={8} ph={16} mt={12} mb={16} style={{ borderRadius: 5 }}>
                <b style={{ fontSize: 23 }}>{product.attributes?.barcode?.value}</b>
                {/* <b style={{ fontSize: 23 }}>{product.code}</b> */}
                <span style={{ fontSize: 16 }}>{product.description}</span>
              </Box>
              <StatusChip
                status={`${decodedItem.item?.zone?.place.description ?? decodedItem.item?.zone?.place.code} - ${
                  decodedItem.item?.zone?.description ?? decodedItem.item?.zone?.code
                }`}
              />
              <Box row>
                {decodedItem.statuses?.map((status, index) => (
                  <StatusChip operation={this.props.operation} status={status} index={index} />
                ))}
              </Box>
            </Box>
          </Box>
          <Box center>
            {!!onActionItem && (
              <Box center mb={10} onClick={() => onActionItem(this.props.decodedItem)}>
                <Icons.Confirmed style={{ width: 38 }} />
              </Box>
            )}
            <Box center mh={10}>
              <Icons.Info style={{ width: 50 }} />
            </Box>
          </Box>
        </Row>
      </>
    )
  }

  render() {
    const { decodedItem } = this.props
    if (!decodedItem.item?.id) return this.renderUnknownItemRow()

    return this.renderItemRow()
  }
}

const Row = styled(Box)`
  margin-bottom: 20px;
  border: 2px solid #edecec;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background2};
`

const ReadIdentifierCode = styled.p`
  font-weight: bold;
  font-size: 16pt;
`
const IdentifierCode = styled.p`
  font-size: 16pt;
`
const SmallValue = styled.p`
  font-weight: bold;
  font-size: 12pt;
`
const SmallLabel = styled.p`
  font-size: 12pt;
  margin-right: 5px;
`
