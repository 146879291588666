import { OperationConfigurations } from 'core/api'
import { RawSchemaField } from 'stylewhere/shared'
import { navigate } from './router'
import { showToast } from './utils'

interface BaseOperationConfig {
  type:
    | 'TRANSFER_TO_ZONE'
    | 'SIMPLE_ITEM_LIST'
    | 'SIMPLE_PARCEL_LIST'
    | 'INBOUND'
    | 'OUTBOUND'
    | 'PACK'
    | 'SORTING'
    | 'ENCODING'
  id: string
  creationDate: Date
  lastModifyDate: Date
  code: string
  description: string
  displayPriority: number
  formSchema?: RawSchemaField[]
  enabled: boolean
  clients: string[]
  attributes: {
    key: string
    attr: string
  }
}

interface RawOperationConfig extends BaseOperationConfig {
  options: {
    customExtensionCode: string
    errorStates: string
    ignoreStates: string
    warningStates: string
    readingMode: 'rfid' | 'mixed' | 'barcode'
    shippingMode: 'parcelByParcel' | 'multiParcel' | 'shipment'
    canConfirmWithMissing: 'no' | 'yes' | 'withWarning'
    canConfirmWithOverQuantity: 'no' | 'yes' | 'withWarning'
    canConfirmWithUnexpected: 'no' | 'yes' | 'withWarning'
    createChecklistOnConfirm: 'true' | 'false'
    hasChecklist: 'true' | 'false'
    hasMovement: 'true' | 'false'
    autostartAntenna: 'true' | 'false'
    startWithRfidSearch: 'true' | 'false'
    confirmOnItemRead: 'true' | 'false'
    readingsDisplayMode: 'groupedByProduct' | 'item'
    'stw.core.logistics.commons.config.requiredparcelcode': 'true' | 'false'
    enableOriginZone: 'true' | 'false'
    enablePicking: 'true' | 'false'
    showDeleteButton: 'true' | 'false'
    showCloseButton: 'true' | 'false'
  }
}

export interface OperationConfigCore extends BaseOperationConfig {
  itemStatuses: {
    error: string[]
    warning: string[]
    ignore: string[]
  }
  autostartAntenna: boolean
  readingsDisplayMode: 'groupedByProduct' | 'item'
}

export interface TransferToZoneOperationConfig extends OperationConfigCore {
  type: 'TRANSFER_TO_ZONE'
}

export interface SimpleItemListOperationConfig extends OperationConfigCore {
  type: 'SIMPLE_ITEM_LIST'
  customExtensionCode: string
  confirmOnItemRead: boolean
}

export interface SimpleParcelListOperationConfig extends OperationConfigCore {
  type: 'SIMPLE_PARCEL_LIST'
}

export interface ShippingOperationConfig extends OperationConfigCore {
  type: 'INBOUND' | 'OUTBOUND' | 'PACK'
  hasChecklist: boolean
  readingMode: 'rfid' | 'mixed' | 'barcode'
  shippingMode: 'parcelByParcel' | 'multiParcel' | 'shipment'
  canConfirmWithMissing: 'no' | 'yes' | 'withWarning'
  canConfirmWithOverQuantity: 'no' | 'yes' | 'withWarning'
  canConfirmWithUnexpected: 'no' | 'yes' | 'withWarning'
  requiredParcelCode?: boolean
  startWithRfidSearch: boolean
}

export interface InboundOperationConfig extends ShippingOperationConfig {
  type: 'INBOUND'
}

export interface OutboundOperationConfig extends ShippingOperationConfig {
  type: 'OUTBOUND'
}

export interface PackOperationConfig extends ShippingOperationConfig {
  type: 'PACK'
}

export interface SortingOperationConfig extends OperationConfigCore {
  type: 'SORTING'
  enableOriginZone: boolean
  enablePicking: boolean
  showCloseButton: boolean
  showDeleteButton: boolean
}

export interface EncodingOperationConfig extends OperationConfigCore {
  type: 'ENCODING'
}

export type OperationConfig =
  | TransferToZoneOperationConfig
  | SimpleItemListOperationConfig
  | SimpleParcelListOperationConfig
  | InboundOperationConfig
  | OutboundOperationConfig
  | PackOperationConfig
  | ShippingOperationConfig
  | SortingOperationConfig
  | EncodingOperationConfig

export class RemoteOperationBase {
  operations: OperationConfig[] = []

  async load(): Promise<void> {
    const rawOperations = await OperationConfigurations.search<RawOperationConfig>({
      size: 10000,
      clients: 'station',
      enabled: true,
      showUserOperations: true,
    })
    this.operations = rawOperations.content.map(({ options, ...rawOperation }) => {
      const base: OperationConfigCore = {
        ...rawOperation,
        itemStatuses: {
          error: options.errorStates?.split(',') ?? [],
          warning: options.warningStates?.split(',') ?? [],
          ignore: options.ignoreStates?.split(',') ?? [],
        },
        autostartAntenna: options.autostartAntenna === 'true',
        readingsDisplayMode: options.readingsDisplayMode ?? 'groupedByProduct',
      }

      if (base.type === 'INBOUND' || base.type === 'OUTBOUND' || base.type === 'PACK') {
        return {
          ...base,
          type: base.type,
          hasChecklist: options.hasChecklist === 'true',
          readingMode: options.readingMode ?? 'rfid',
          shippingMode: options.shippingMode ?? 'parcelByParcel',
          canConfirmWithMissing: options.canConfirmWithMissing ?? 'withWarning',
          canConfirmWithOverQuantity: options.canConfirmWithOverQuantity ?? 'withWarning',
          canConfirmWithUnexpected: options.canConfirmWithUnexpected ?? 'withWarning',
          startWithRfidSearch: options.startWithRfidSearch === 'true',
        }
      }
      if (base.type === 'SIMPLE_ITEM_LIST') {
        return {
          ...base,
          type: base.type,
          customExtensionCode: options.customExtensionCode,
          confirmOnItemRead: options.confirmOnItemRead === 'true',
        }
      }
      if (base.type === 'SIMPLE_PARCEL_LIST') {
        return {
          ...base,
          type: base.type,
        }
      }
      if (base.type === 'SORTING') {
        return {
          ...base,
          type: base.type,
          enableOriginZone: options.enableOriginZone === 'true',
          enablePicking: options.enablePicking === 'true',
          showCloseButton: options.showCloseButton === 'true',
          showDeleteButton: options.showDeleteButton === 'true',
        }
      }
      return { ...base, type: base.type }
    })
  }

  getOperationConfig<T extends OperationConfig = OperationConfig>(code: string): T {
    const operation = this.operations.find((o) => o.code === code)
    if (!operation) {
      showToast({ status: 'error', description: `Operation ${code} not found.` })
      navigate('/')
    }
    return operation as T
  }
}

export default new RemoteOperationBase()
