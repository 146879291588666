import { Component } from 'react'
import { OperationStart } from 'stylewhere/components'
import { Router, getDataFromSchema, ShippingOperationConfig, RemoteOperation, RfidReader } from 'stylewhere/shared'
import { ShippingExtensions } from 'stylewhere/extensions'
import { showToastError } from 'stylewhere/utils'
import { Shippings, TmrTag } from 'stylewhere/api'
import type { Routes } from 'stylewhere/pages'
import { RouteComponentProps } from 'react-router'

interface Params {
  opCode: string
}

export default class ShippingStart extends Component<RouteComponentProps<Params>> {
  submitPath: Routes = '/shipping/:opCode/reading'
  operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(Router.getMatchParams(this.props).opCode)
  checkingIdentifier = false

  componentDidMount() {
    if (this.operation.startWithRfidSearch) {
      RfidReader.initialize()
      RfidReader.onTagReadCallback = this.onTagReadCallback
    }
  }

  onTagReadCallback = async (tag: TmrTag) => {
    if (this.checkingIdentifier) return
    this.checkingIdentifier = true
    RfidReader.stop()
    RfidReader.clear()
    try {
      const parcelByIdentifier = await Shippings.parcelByIdentifier(this.operation, tag.epc)
      const parcel = await Shippings.startParcel(this.operation, {
        parcelCode: parcelByIdentifier.code,
        attributes: parcelByIdentifier.attributes,
        operationId: this.operation.id,
      })
      Router.navigate(
        this.submitPath,
        { opCode: this.operation.code },
        { state: { formData: {}, parcel, tags: [tag] } }
      )
    } catch (error) {
      showToastError(error)
    }
    this.checkingIdentifier = false
  }

  onSubmit = async (formData, operation, schema) => {
    try {
      if (operation.hasChecklist) {
        const parcel = await Shippings.startParcel(operation, getDataFromSchema(formData, schema))
        Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData, parcel } })
      }
      Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData } })
    } catch (err) {
      showToastError(err, 'Start Parcel Error')
    }
  }

  render() {
    return (
      <OperationStart
        startWithRfidSearch={this.operation.startWithRfidSearch}
        submitPath={this.submitPath}
        extensions={ShippingExtensions}
        onSubmit={this.onSubmit}
      />
    )
  }
}
