import { Component } from 'react'
import { RouteComponentProps, useParams } from 'react-router'
import { Sortings, Sorting } from 'stylewhere/api'
import { Icons, OperationStart } from 'stylewhere/components'
import { SortingExtensions } from 'stylewhere/extensions'
import { FormSchema, RemoteOperation, Router, SortingOperationConfig } from 'stylewhere/shared'
import { showToastError } from 'stylewhere/shared/utils'

export default class SortingStart extends Component<RouteComponentProps<{ opCode: string; code?: string }>> {
  opCode = Router.getMatchParams(this.props).opCode
  operation = RemoteOperation.getOperationConfig<SortingOperationConfig>(this.opCode)
  code = Router.getMatchParams(this.props).code
  submitPath = '/sorting/:opCode/reading' as any

  onSubmitForm = async (formData: any, operation: SortingOperationConfig, schema: FormSchema) => {
    if (formData.pickingIds) {
      try {
        const res = await Sortings.createFromPicking<any>(
          formData.pickingIds.map((p) => p.id),
          operation.id,
          formData
        )
        Router.navigate(
          this.submitPath,
          { opCode: operation.code },
          { state: { formData: { ...formData, sortingId: res.id } } }
        )
      } catch (error) {
        showToastError(error)
      }
    } else {
      Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData } })
    }
  }

  render() {
    return (
      <OperationStart
        backPath="/sorting/:opCode"
        submitPath={this.submitPath}
        onSubmit={this.onSubmitForm}
        extensions={SortingExtensions}
      />
    )
  }
}
