import { Component } from 'react'
import { DecodedItem, Shippings, ShippingParcel } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import {
  Router,
  RemoteOperation,
  AppStore,
  OperationReadingState,
  OperationReadingProps,
  checkRequiredField,
  getDataFromSchema,
  RfidReader,
  OperationReadingProvider,
} from 'stylewhere/shared'
import { ShippingExtensions } from 'stylewhere/extensions'
import { ShippingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import {
  AntennaButton,
  Box,
  Button,
  OperationReadingCounters,
  OperationReadingList,
  Page,
  Spacer,
  TagCounter,
} from 'stylewhere/components'
import { showToast, showToastError } from 'stylewhere/shared/utils'

interface State extends OperationReadingState {
  parcel?: ShippingParcel
}

export default class ShippingReading extends Component<OperationReadingProps<State>, State> {
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(this.matchParams.opCode)
  formSchema = ShippingExtensions.formSchema(this.operation, { parcel: this.locationState.parcel })

  state: State = {
    items: [],
    loading: true,
    formData: this.locationState.formData ?? {},
  }

  async componentDidMount() {
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)
    this.setState({ parcel: this.locationState.parcel })
  }

  setRfidReaderDecode = () => {
    const { parcel } = this.state
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    decodePayload.operationId = this.operation.id
    if (parcel) {
      decodePayload.parcelCode = parcel.code
    }
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, {
      url: Shippings.batchValidateEndpoint(this.operation),
      payload: { ...decodePayload, asn: undefined },
    })
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    const { items, formData } = this.state
    const processedItems = await OperationReadingProvider.processDecodedItems(
      this.operation,
      itemMapFromReader,
      items,
      formData,
      ShippingExtensions
    )
    this.setState({ items: processedItems })
  }

  removeItem(decodedItem: DecodedItem) {
    const items = OperationReadingProvider.removeItem(decodedItem, this.state.items)
    this.setState({ items })
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/shipping/:opCode', { opCode: this.operation.code })
    } else {
      Router.navigate('/')
    }
  }

  onClear = () => {
    RfidReader.clear()
    this.setState({ items: [] })
  }

  onConfirm = async () => {
    try {
      let { parcel } = this.state

      /**
       * check if some data of schema is required and undefined
       * if check is false then open Modal Form
       * **/
      if (!checkRequiredField(this.state.formData, this.formSchema)) {
        try {
          await new Promise((resolve, reject) => {
            AppStore.callbacks.openModalForm(resolve, reject)
          })
        } catch (error) {
          return
        }
      }

      const { items, formData } = this.state
      const confirmData = getDataFromSchema(formData, this.formSchema)
      if (!parcel && !this.operation.hasChecklist) {
        if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing worksation place')
        parcel = await Shippings.startParcel(this.operation, {
          operationId: this.operation.id,
          attributes: confirmData.attributes,
          ...confirmData,
          asn: { ...confirmData.asn, originPlaceId: AppStore.defaultWorkstation?.placeId },
        })
      }

      if (!parcel) throw new Error('Missing parcel')

      await ShippingExtensions.beforeConfirm(this.operation, confirmData, items)

      parcel = await Shippings.updateParcel(this.operation, {
        operationId: this.operation.id,
        itemIds: items.flatMap(({ item }) => (item?.id ? item.id : [])),
        action: 'INSERT',
        parcelCode: parcel.code,
      })

      const confirmResult = await Shippings.confirmParcel(this.operation, {
        parcelCode: parcel.code,
        operationId: this.operation.id,
        attributes: confirmData.attributes,
      })

      await ShippingExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      this.goBack()
    } catch (err: any) {
      showToastError(err)
    }
  }

  showConfirmButton() {
    const { items } = this.state
    if (items.filter((itm) => itm.item?.status !== 'ignored').length > 0)
      return <Button title={__(T.misc.confirm)} onClick={this.onConfirm} />
    return null
  }

  render() {
    const { items, formData, loading, parcel } = this.state
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
            setFormData: async (fd) => {
              if (!(await ShippingExtensions.formDataIsValid(fd, this.operation, this.formSchema))) return
              this.setState({ formData: fd })
              this.setRfidReaderDecode()
            },
          },
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter
              detected={items.length}
              expected={parcel?.checklist?.checklistItems?.length ?? 0}
              unexpected={items.filter((itm) => itm.item?.status === 'error').length}
            />
            <AntennaButton onClear={this.onClear} hideClear={items.length === 0} />
            <Spacer />
            <OperationReadingCounters operation={this.operation} items={items} />
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <OperationReadingList
            removeItemCallback={this.removeItem}
            extension={ShippingExtensions}
            items={items}
            operation={this.operation}
            parcel={parcel}
          />
        </Page.Content>
      </Page>
    )
  }
}
