import { Component } from 'react'
import { FormSchemaForm } from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import { FormSchemaData } from 'stylewhere/shared'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'

interface Props {
  operation: EncodingOperationConfig
  onSubmit: (data: FormSchemaData) => void
}

interface State {}

export class EncodingForm extends Component<Props, State> {
  render() {
    const { operation, onSubmit } = this.props
    return (
      <FormSchemaForm
        flex
        style={{ justifyContent: 'space-between' }}
        schema={EncodingExtensions.formSchema(operation)}
        onSubmit={onSubmit}
        submitText="Avanti"
      />
    )
  }
}
