import Dashboard from './Dashboard'
import TagInfo from './TagInfo/TagInfo'
import TransferToZoneStart from './TransferToZone/TransferToZoneStart'
import TransferToZoneReading from './TransferToZone/TransferToZoneReading'
import SimpleItemListStart from './SimpleItemList/SimpleItemListStart'
import SimpleItemListReading from './SimpleItemList/SimpleItemListReading'
import SimpleParcelListStart from './SimpleParcelList/SimpleParcelListStart'
import SimpleParcelListReading from './SimpleParcelList/SimpleParcelListReading'
import ShippingStart from './Shipping/ShippingStart'
import ShippingReading from './Shipping/ShippingReading'
import SortingList from './Sorting/SortingList'
import SortingStart from './Sorting/SortingStart'
import SortingReading from './Sorting/SortingReading'
import Login from './Login'
import ConnectionError from './ConnectionError'
import EncodingEncode from './Encoding/EncodingEncode'
import EncodingVerify from './Encoding/EncodingVerify'

export const Routes = {
  '/': { component: Dashboard },
  '/tag-info': { component: TagInfo },
  '/transfer-to-zone/:opCode': { component: TransferToZoneStart },
  '/transfer-to-zone/:opCode/reading': { component: TransferToZoneReading },
  '/simple-item-list/:opCode': { component: SimpleItemListStart },
  '/simple-item-list/:opCode/reading': { component: SimpleItemListReading },
  '/simple-parcel-list/:opCode': { component: SimpleParcelListStart },
  '/simple-parcel-list/:opCode/reading': { component: SimpleParcelListReading },
  '/shipping/:opCode': { component: ShippingStart },
  '/shipping/:opCode/reading': { component: ShippingReading },
  '/sorting/:opCode': { component: SortingList },
  '/sorting/:opCode/start/:code': { component: SortingStart },
  '/sorting/:opCode/start': { component: SortingStart },
  '/sorting/:opCode/reading': { component: SortingReading },
  '/encoding/:opCode': { component: EncodingEncode },
  '/encoding/:opCode/verify': { component: EncodingVerify },
  '/login': { component: Login, public: true },
  '/connection-error': { component: ConnectionError, public: true, private: true },
}
