import { Component } from 'react'
import styled from '@emotion/styled'
import { Modal, TextArea, Box, Button } from 'stylewhere/components'
import { AppStore, Emulation } from 'core/shared'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  title: string
  isOpen?: boolean
  onConfirm: (input?: string) => void
  onClose: () => void
  style?: any
}

interface State {
  input?: string
  savedEmulationsList: Emulation[]
}

export class EmulationModal extends Component<Props, State> {
  state: State = {
    savedEmulationsList: [...AppStore.emulationList],
  }

  saveEmulate = async () => {
    const { input } = this.state
    const name = prompt('Insert emulation name', '')
    if (!name || !input) return

    // eslint-disable-next-line react/no-access-state-in-setstate
    const newEmulations = [...this.state.savedEmulationsList].concat({ name, value: input })
    await AppStore.saveEmulationList(newEmulations)
    this.setState({ savedEmulationsList: newEmulations })
  }

  deleteEmulate = async (name: string) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const filteredEmulations = this.state.savedEmulationsList.filter((em) => em.name !== name)
    await AppStore.saveEmulationList(filteredEmulations)
    this.setState({ savedEmulationsList: filteredEmulations })
  }

  onSubmit = (text?: string) => {
    const emulationText = text ?? this.state.input
    const cleaned = String(emulationText)
      .trim()
      .replace(/ /g, '')
      .replace(/"/g, '')
      .replace(/'/g, '')
      .replace(/;/g, ',')
      .replace(/,,/g, ',')
      .replace(/\n\r/g, ',')
      .replace(/\n/g, ',')
      .replace(/\t/g, ',')
      .trim()
    this.props.onConfirm(cleaned)
  }

  render() {
    const { isOpen, onClose, title } = this.props
    const { savedEmulationsList, input } = this.state

    const hintText = 'Press [CTRL+E] to show again'
    return (
      <Modal visible={isOpen ?? true} onClose={onClose} size="3xl">
        <Modal.Title>{title}</Modal.Title>
        <Box row>
          <Box mt={20} style={{ position: 'relative' }} flex={10}>
            <TextArea
              placeholder={__(T.messages.paste_comma_separeted_tags)}
              onChange={(newValue) => this.setState({ input: newValue })}
              startFocus
              textAreaStyle={{ height: 300, resize: 'none' }}
              onEnter={() => this.onSubmit()}
            />
            {input && (
              <Box row style={{ position: 'absolute', right: 15, bottom: 20 }}>
                <Button
                  title={__(T.misc.save_to_list)}
                  onClick={this.saveEmulate}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                  variant="default"
                  size="small"
                />
                <Button
                  title="Emulate"
                  onClick={() => this.onSubmit()}
                  style={{
                    whiteSpace: 'nowrap',
                    marginLeft: 15,
                  }}
                  size="small"
                />
              </Box>
            )}
          </Box>
          {!!savedEmulationsList.length && (
            <Box flex={0.5} mt={20} pl={15}>
              <SavedListTitle>Saved List</SavedListTitle>
              {savedEmulationsList.map((elem) => (
                <Box key={elem.name} row flex={0} center mb={10}>
                  <Button
                    style={{ marginRight: 10, flex: 1, whiteSpace: 'nowrap' }}
                    title={elem.name}
                    onClick={() => this.onSubmit(elem.value)}
                    variant="default"
                    size="small"
                  />
                  <Button
                    title="X"
                    onClick={() => {
                      this.deleteEmulate(elem.name)
                    }}
                    variant="default"
                    size="small"
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>

        <Box row mt={30} vcenter>
          <Button variant="default" title="Hide Emulation" onClick={onClose} size="small" />
          <Hint>{hintText}</Hint>
        </Box>
      </Modal>
    )
  }
}

const Hint = styled.span`
  font-size: 19px;
  font-weight: 400;
  text-align: center;
  color: rgba(51, 51, 51, 0.52);
  margin-left: 15px;
`

const SavedListTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
`
