import styled from '@emotion/styled'
import { Skeleton } from '@chakra-ui/react'
import { get as _get } from 'lodash'
import { Box, EmptyList } from 'stylewhere/components'

/**
 * How to use:
 *
 * Props:
 *  - data: Array of object to show
 *  - structure: Array of object when specify the caption, value and width of each column or a customRender with function
 *  - header: Boolean to show or hide the columns header
 *
 * Ex.
 * 
 * structure = [
    {
      label: 'Numero busta',
      value: 'busta',
      flex: 3,
      customRender: (item, column) => Element
    },
    {
      label: 'Data',
      value: 'dataNumero',
      flex: 1,
    },
    {
      label: 'Numero busta',
      value: 'busta',
      flex: 1,
    },
  ]
 *
 *
 * data = [
    {
      id: 'ciao',
      busta: '123',
      dataNumero: '01/05/2019',
      utente: 'Daniele Verdone',
      insurance: '3000',
      items: [{}, {}, {}, {}],
    },
    {
      id: 'prova',
      busta: '456',
      dataNumero: '01/05/2019',
      utente: 'Daniele Verdone',
      insurance: '6000',
      items: [{}, {}, {}, {}],
    },
  ]
  */

export interface TableStructure<T> {
  key: string
  label?: string
  value?: string
  width?: number | string
  flex?: number | string
  style?: React.CSSProperties
  customValue?: (item: T, column: number) => string | undefined
  customRender?: (item: T, column: number) => React.ReactNode
}

interface Props<T> {
  loading?: boolean
  data?: T[]
  structure: TableStructure<T>[]
  onRowPress?: (item: T) => void
  showHeader?: boolean
  showFooter?: boolean
  headerStyle?: React.CSSProperties
  bodyStyle?: React.CSSProperties
  footerStyle?: React.CSSProperties
  rowBodyStyle?: React.CSSProperties
  headerCellStyle?: React.CSSProperties
  bodyCellStyle?: React.CSSProperties
  style?: React.CSSProperties
  defaultValue?: string
  emptyStyle?: React.CSSProperties
}

export function Table<T extends { id: string }>({
  loading = false,
  data,
  structure,
  onRowPress,
  showHeader = true,
  showFooter = false,
  headerStyle,
  bodyStyle,
  footerStyle,
  headerCellStyle,
  bodyCellStyle,
  rowBodyStyle,
  style,
  defaultValue = '---',
  emptyStyle,
}: Props<T>) {
  const getStyleFlex = (item: TableStructure<T>, customStyle?: React.CSSProperties) => {
    let styleFlex
    if (item.width !== undefined) {
      styleFlex = { width: item.width }
    } else {
      styleFlex = { flex: item.flex !== undefined ? item.flex : 1 }
    }

    return {
      ...styleFlex,
      ...customStyle,
      ...item.style,
    }
  }

  const renderHeader = () => {
    return (
      <Header row style={headerStyle}>
        {structure.map((x) => (
          <HeaderCell vcenter key={`header${x.key}`} style={getStyleFlex(x, headerCellStyle)}>
            {!!x.label && <div>{x.label}</div>}
          </HeaderCell>
        ))}
        {/* {!!iconName && !!iconColor && !!onIconPress && <View />} */}
      </Header>
    )
  }

  const renderPlaceholder = () => (
    <Body style={bodyStyle}>
      {[...Array(1)].map((d, dIndex) => (
        <Row style={{ height: 100 }} onClick={onRowPress} row key={`row${dIndex}`}>
          {structure.map((struct) => (
            <RowCell vcenter last={dIndex === 4} key={`cell${struct.key}`} style={getStyleFlex(struct, bodyCellStyle)}>
              <Skeleton startColor="#E6E6E6" endColor="#c8c8c8" height="20px" marginRight="20px" isLoaded={!loading} />
            </RowCell>
          ))}
        </Row>
      ))}
    </Body>
  )

  const renderBody = () => {
    if (data?.length === 0) {
      return <EmptyList style={emptyStyle} />
    }
    return (
      <Body style={bodyStyle}>
        {data?.map((d, dIndex) => (
          <Row style={rowBodyStyle} onClick={onRowPress} row key={`row${d.id}`}>
            {structure.map((struct) => (
              <RowCell
                vcenter
                last={dIndex === data.length - 1}
                key={`cell${struct.key}`}
                style={getStyleFlex(struct, bodyCellStyle)}
              >
                {struct.customValue?.(d, dIndex)}
                {(!struct.customValue && struct.customRender?.(d, dIndex)) ?? (
                  <>{struct.value && (_get(d, struct.value) ?? defaultValue)}</>
                )}
              </RowCell>
            ))}
          </Row>
        ))}
      </Body>
    )
  }

  const renderFooter = () => {
    return <Footer style={footerStyle} />
  }

  return (
    <Grid style={style}>
      {showHeader && renderHeader()}
      {loading && renderPlaceholder()}
      {!loading && renderBody()}
      {showFooter && renderFooter()}
    </Grid>
  )
}

const Colors = {
  headerBg: '#f1f1f1',
  border: '#E0E0E0',
}

const Grid = styled.div`
  display: grid;
  padding: 1px;
  grid-template-areas:
    'header'
    'body'
    'footer';
`

const Header = styled(Box)`
  position: sticky;
  z-index: 1;
  min-height: 60px;
  grid-area: header;
  top: 0;
`

const Body = styled.div`
  grid-area: body;
`

const Row = styled(Box)`
  min-height: 60px;
`

const Footer = styled.div`
  min-height: 60px;
  grid-area: footer;
`

const Cell = styled(Box)`
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 -1px -1px 0;
`

const HeaderCell = styled(Cell)`
  background-color: ${Colors.headerBg};
  font-weight: 700;
  border: 1px solid ${Colors.border};
  &:first-of-type {
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
  }
`

const RowCell = styled(Cell)<{ last: boolean }>`
  border: 1px solid ${Colors.border};
  &:first-of-type {
    ${({ last }) => last && 'border-bottom-left-radius: 10px;'}
  }

  &:last-child {
    ${({ last }) => last && 'border-bottom-right-radius: 10px;'}
  }
`
