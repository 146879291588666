import { Box } from 'stylewhere/components'
import { OperationConfig } from 'stylewhere/shared'

export const StatusChip = ({
  status,
  index,
  operation,
}: {
  status: string
  index?: number
  operation?: OperationConfig
}) => {
  const chipStyle = {
    warning: { backgroundColor: '#FFEFC6', color: '#D6A52A' },
    error: { backgroundColor: '#FF8474', color: '#e0402b' },
    default: { backgroundColor: '#DAE6ED', color: '#5E5E5E' },
  }
  let statusType = 'default'
  if (operation?.itemStatuses.warning.includes(status)) statusType = 'warning'
  if (operation?.itemStatuses.error.includes(status)) statusType = 'error'

  return (
    <Box
      key={index}
      mr={4}
      mb={4}
      pv={5}
      ph={8}
      style={{ ...chipStyle[statusType], width: 'fit-content', borderRadius: 5 }}
    >
      <b>{status?.replaceAll('_', ' ')}</b>
    </Box>
  )
}
