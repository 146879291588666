import { DecodedItem } from 'stylewhere/api'
import { GroupedItemRow, List } from 'stylewhere/components'
import { OperationConfig } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'

interface Props<OC extends OperationConfig> {
  rows: DecodedItem[]
  loading?: boolean
  onRemoveItem?: (item: DecodedItem) => void
  onActionItem?: (item: DecodedItem) => void
  operation?: OC
}

export function GroupedItemList<OC extends OperationConfig>({
  rows,
  loading = false,
  onRemoveItem,
  onActionItem,
  operation,
}: Props<OC>) {
  function sortFn(a: DecodedItem) {
    if (a.item?.__unexpected) return -1
    return 0
  }

  const rowRender = (item, index) => {
    return (
      <GroupedItemRow
        key={index}
        decodedItem={item}
        operation={operation}
        onRemoveItem={onRemoveItem}
        onActionItem={onActionItem}
      />
    )
  }

  if (loading) {
    return (
      <>
        <GroupedItemRow.Skeleton />
        <GroupedItemRow.Skeleton />
      </>
    )
  }

  return (
    <List
      data={rows.sort(sortFn).sort((a, b) => {
        if (a.item?.status === 'error') return -1
        if (b.item?.status === 'error') return 1
        if (a.item?.status === 'warning') return -1
        if (b.item?.status === 'warning') return 1
        if (a.item?.status === 'ignored') return -1
        if (b.item?.status === 'ignored') return 1
        return 0
      })}
      emptyMessage={<span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.press_start_to_read)}</span>}
      rowRender={rowRender}
    />
  )
}
