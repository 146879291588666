import { Component, useEffect, useState } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import {
  AntennaButton,
  Page,
  Spacer,
  Box,
  Button,
  Icons,
  EmptyListMessage,
  Modal,
  Table,
  Input,
} from 'stylewhere/components'
import {
  DecodedItemSorting,
  Sorting,
  SortingGroup,
  SortingGroupEntries,
  SortingGroupEntry,
  SortingGroupRead,
  SortingGroupReads,
  SortingGroups,
  Sortings,
  SortingsDecodePayload,
} from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import {
  Router,
  RemoteOperation,
  SortingOperationConfig,
  OperationReadingProps,
  OperationReadingState,
  AppStore,
  RfidReader,
  getDataFromSchema,
  OperationReadingProvider,
  usePrevState,
} from 'stylewhere/shared'
import { SortingExtensions } from 'stylewhere/extensions'
import { showToast, showToastError, askUserConfirmation } from 'stylewhere/utils'

const getEpc = (item: DecodedItemSorting['item']) =>
  item?.identifiers.find(({ type }) => type === 'UHF_TAG')?.code ?? 'n/a'

const getSerial = (item?: DecodedItemSorting['item']) =>
  item?.identifiers.find(({ type, role }) => type === 'SIMPLE_ITEM_IDENTIFIER' && role === 'serial')?.code ?? 'n/a'

interface State extends OperationReadingState {
  items: DecodedItemSorting[]
  sorting?: Sorting
  currentItem?: DecodedItemSorting
  currentSortingGroupRead?: SortingGroupRead
  currentTag?: string
}

export default class SortingReading extends Component<OperationReadingProps<State>, State> {
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<SortingOperationConfig>(this.matchParams.opCode)
  formSchema = SortingExtensions.formSchema(this.operation)

  state: State = {
    items: [],
    loading: true,
    formData: this.locationState.formData,
  }

  async componentDidMount() {
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)
    this.state.formData && this.setState({ sorting: this.state.formData.sortingId })
  }

  setRfidReaderDecode = () => {
    RfidReader.setDecodeFunction(this.decodeFunction)
  }

  decodeFunction = async (identifierCodes: string[]) => {
    const decodePayload = getDataFromSchema<SortingsDecodePayload>(this.state.formData, this.formSchema)
    decodePayload.operationId = this.operation.id
    // La Sorting non usa originZoneId nella confirm (confirmValuePath = false nel formSchema),
    // ma lo richiede nella read, quindi va aggiunto qui a mano
    if (this.operation.enableOriginZone) {
      decodePayload.originZoneId = this.state.formData.originZoneId.id
    }
    // Prendi solo il primo item letto, gli altri sono ignorati
    decodePayload.itemIdentifierCode = identifierCodes[0]
    const currentItem = await Sortings.decode(decodePayload)
    const currentSortingGroupRead = currentItem.sortingGroupRead

    const items = await OperationReadingProvider.processDecodedItems<DecodedItemSorting[]>(
      this.operation,
      { [identifierCodes[0]]: currentItem },
      this.state.items,
      this.state.formData,
      SortingExtensions
    )

    if (currentItem.item) {
      if (currentItem.statuses.includes('ALREADY_READ')) {
        currentItem.item.status = 'ignored'
        // Non aggiornare gli items nello stato perché non deve apparire nei contatori
        this.setState({ currentItem, currentSortingGroupRead })
        return
      }
      if (currentItem.statuses.includes('ITEM_UNKNOWN') || currentItem.statuses.includes('INVALID_IDENTIFIER')) {
        // Devo visualizzare il tag ma non ho l'item, lo salvo quindi nello stato
        this.setState({ currentTag: identifierCodes[0] })
      } else if (currentItem.statuses.length === 0) {
        // Se non ci sono errori, aggiorno la sorting per aggiornare i contatori
        this.setState({ sorting: await Sortings.getById(this.state.sorting!.id) })
      }

      this.setState({ items, currentItem, currentSortingGroupRead })
    }
  }

  removeItem(decodedItem: DecodedItemSorting) {
    const items = OperationReadingProvider.removeItem(decodedItem, this.state.items)
    this.setState({ items })
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/sorting/:opCode', { opCode: this.operation.code })
    } else {
      Router.navigate('/')
    }
  }

  onConfirm = async () => {
    const { items, formData } = this.state
    try {
      const confirmData = getDataFromSchema(formData, this.formSchema)
      await SortingExtensions.beforeConfirm(this.operation, confirmData, items)
      if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing worksation place')
      const confirmResult = await Sortings.close({
        ...confirmData,
        operationId: this.operation.id,
        sortingId: this.state.sorting!.id,
      })
      await SortingExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      this.goBack()
    } catch (err) {
      showToastError(err)
    }
  }

  async delete() {
    if (
      await askUserConfirmation(
        __(T.confirm.confirm_delete_operation_title, { operation: this.operation.description }),
        __(T.confirm.confirm_delete_operation)
      )
    ) {
      await Sortings.delete({ sortingId: this.state.sorting!.id, operationId: this.operation.id })
    }
  }

  itemIsOk(item: DecodedItemSorting) {
    return item.item?.status !== 'ignored' && item.item?.status !== 'error'
  }

  render() {
    const { items, formData, loading, currentItem, currentSortingGroupRead, currentTag, sorting } = this.state
    if (!sorting) return null
    const read = sorting.readsCount
    const expected = sorting.expectedCount
    const missing = expected - read > 0 ? expected - read : 0
    const unexpected = items.filter((itm) => itm.item?.status === 'error').length
    const ignored = items.filter((itm) => itm.item?.status === 'ignored').length

    // Stati di errore
    const isOk = currentItem ? this.itemIsOk(currentItem) : false
    let errorMessage
    if (!isOk) {
      if (currentItem?.statuses.includes('ALREADY_READ')) {
        errorMessage = __(T.error.sorting_already_read, {
          serial: getSerial(currentItem.item),
          group: currentSortingGroupRead?.assignedEntry.sortingGroup.code,
        })
      } else if (currentItem?.statuses.includes('SORTING_GROUP_NOT_FOUND')) {
        errorMessage = __(T.error.sorting_no_group)
      } else if (currentItem?.statuses.includes('NOT_IN_ZONE')) {
        errorMessage = __(T.error.sorting_not_in_zone, {
          serial: getSerial(currentItem.item),
          zone: currentItem.item?.zone?.description,
        })
      } else if (
        currentItem?.statuses.includes('ITEM_UNKNOWN') ||
        currentItem?.statuses.includes('INVALID_IDENTIFIER')
      ) {
        errorMessage = __(T.error.sorting_unknown, { tag: currentTag })
      } else if (currentItem?.statuses.includes('UNEXPECTED')) {
        errorMessage = __(T.error.sorting_unexpected)
      }
    }

    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        forceDetails="top"
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
            setFormData: async (fd) => {
              if (!(await SortingExtensions.formDataIsValid(fd, this.operation, this.formSchema))) return
              this.setState({ formData: fd })
              this.setRfidReaderDecode()
            },
          },
        }}
        enableEmulation
      >
        <Page.Content notBoxed>
          <Main flex row>
            <>
              {!currentItem && (
                <EmptyListMessage center>
                  {__(RfidReader.isReading() ? T.messages.waiting_for_product : T.messages.press_start_to_read)}
                </EmptyListMessage>
              )}
              {currentItem && (
                <ProductBox ok={isOk} width="100%" row>
                  {isOk && (
                    <>
                      <ProductTitle flex={1.8} justify="center" ok pl={50} pr={25}>
                        {currentSortingGroupRead?.assignedEntry.sortingGroup.code}
                      </ProductTitle>
                      <ProductInfoBox operation={this.operation} currentItem={currentItem} />
                    </>
                  )}
                  {!isOk && (
                    <ProductTitle flex justify="center" ph={50} ok={false}>
                      {errorMessage}
                    </ProductTitle>
                  )}
                </ProductBox>
              )}
            </>
          </Main>
          <Spacer />
          <Box row>
            <Counter flex={1.6} mr={12}>
              <Box row mb={10}>
                <IconCheck>
                  <Icons.CheckFilled />
                </IconCheck>
                <CounterTitle>
                  {__(T.misc.read)}/{__(T.misc.expected)}
                </CounterTitle>
              </Box>
              <CounterBody flex center>
                {read}/{expected}
              </CounterBody>
            </Counter>

            <Counter flex mr={12}>
              <Box row mb={10}>
                <IconMissing>
                  <Icons.Minus />
                </IconMissing>
                <CounterTitle>{__(T.misc.missing)}</CounterTitle>
              </Box>
              <CounterBody flex center>
                {missing}
              </CounterBody>
            </Counter>

            <Counter flex mr={12}>
              <Box row mb={10}>
                <IconUnexpected>
                  <Icons.Close />
                </IconUnexpected>
                <CounterTitle>{__(T.misc.unexpected)}</CounterTitle>
              </Box>
              <CounterBody flex center>
                {unexpected}
              </CounterBody>
            </Counter>

            <Counter flex>
              <Box row mb={10}>
                <Box mr={10}>
                  <Icons.Question />
                </Box>
                <CounterTitle>{__(T.misc.ignored_plural)}</CounterTitle>
              </Box>
              <CounterBody flex center>
                {ignored}
              </CounterBody>
            </Counter>
          </Box>
          <Spacer />
          <Flex alignItems="center">
            <Box>
              <AntennaButton style={{ width: 300 }} hideClear showPendingTags={false} />
            </Box>
            <Box flex="auto" />
            {this.operation.showDeleteButton && (
              <Box mr={18}>
                <Button
                  title={__(T.misc.delete)}
                  onClick={() => {
                    this.delete()
                    return true
                  }}
                  variant="secondary"
                />
              </Box>
            )}
            <Box mr={18}>
              <DetailsButton sortingId={sorting.id} />
            </Box>
            {this.operation.showCloseButton && (
              <Box>
                <Button title={__(T.misc.confirm)} onClick={this.onConfirm} />
              </Box>
            )}
          </Flex>
        </Page.Content>
      </Page>
    )
  }
}

const ProductInfoBox: React.FC<{ operation: SortingOperationConfig; currentItem: DecodedItemSorting }> = ({
  operation,
  currentItem,
}) => {
  let info
  if (operation.enablePicking) {
    return (
      <Box flex>
        <ProductInfo flex ph={25} justify="center">
          <ProductInfoLabel>{__(T.misc.customer)}</ProductInfoLabel>
          <ProductInfoValue>?</ProductInfoValue>
        </ProductInfo>
        <ProductInfo flex ph={25} justify="center">
          <ProductInfoLabel>{__(T.misc.barcode_bc)}</ProductInfoLabel>
          <ProductInfoValue>{currentItem.item?.product.attributes?.barcodeBC?.value}</ProductInfoValue>
        </ProductInfo>
        <ProductInfo flex ph={25} justify="center">
          <ProductInfoLabel>{__(T.misc.list)}</ProductInfoLabel>
          <ProductInfoValue>?</ProductInfoValue>
        </ProductInfo>
        <ProductInfo flex ph={25} justify="center">
          <ProductInfoLabel>{__(T.misc.list_position)}</ProductInfoLabel>
          <ProductInfoValue>?</ProductInfoValue>
        </ProductInfo>
      </Box>
    )
  }

  return (
    <Box flex>
      <ProductInfo flex ph={25} justify="center">
        <ProductInfoLabel>EPC</ProductInfoLabel>
        <ProductInfoValue>{getEpc(currentItem.item)}</ProductInfoValue>
      </ProductInfo>
      <ProductInfo flex ph={25} justify="center">
        <ProductInfoLabel>{__(T.misc.barcode_bc)}</ProductInfoLabel>
        <ProductInfoValue>{currentItem.item?.product.attributes?.barcodeBC?.value}</ProductInfoValue>
      </ProductInfo>
      <ProductInfo flex ph={25} justify="center">
        <ProductInfoLabel>{__(T.misc.serial)}</ProductInfoLabel>
        <ProductInfoValue>{getSerial(currentItem.item)}</ProductInfoValue>
      </ProductInfo>
      <ProductInfo flex ph={25} justify="center">
        <ProductInfoLabel>Custom class</ProductInfoLabel>
        <ProductInfoValue>{currentItem.item?.product.attributes?.dimensionsystem?.value ?? 'n/a'}</ProductInfoValue>
      </ProductInfo>
    </Box>
  )
}

type ModalLevels = 'group' | 'entry' | 'read'

const DetailsButton: React.FC<{ sortingId: string }> = ({ sortingId }) => {
  const [level, setLevel] = useState<ModalLevels>('group')
  const [sortingGroups, setSortingGroups] = useState<SortingGroup[]>()
  const [sortingGroup, setSortingGroup] = useState<SortingGroup>()
  const [sortingGroupEntries, setSortingGroupEntries] = useState<SortingGroupEntry[]>()
  const [sortingGroupEntry, setSortingGroupEntry] = useState<SortingGroupEntry>()
  const [sortingGroupReads, setSortingGroupReads] = useState<SortingGroupRead[]>()
  const [isOpen, setIsOpen] = useState(false)
  const prevIsOpen = usePrevState(isOpen)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const levelGroupStructure = [
    { key: 'priority', value: 'priority', label: __(T.misc.priority), width: '18%' },
    { key: 'group', value: 'code', label: __(T.misc.group), width: '33%' },
    {
      key: 'required_quantity',
      customRender: (group) => <ModalLabel style={{ backgroundColor: '#ededed' }}>{group.expectedCount}</ModalLabel>,
      label: __(T.misc.required_quantity),
      width: '19%',
    },
    {
      key: 'sorted_quantity',
      customRender: (group) => (
        <ModalLabel
          style={{
            backgroundColor: group.readsCount < group.expectedCount ? '#ff8474' : '#74ff92',
          }}
        >
          {group.readsCount}
        </ModalLabel>
      ),
      label: __(T.misc.sorted_quantity),
      width: '19%',
    },
    {
      key: 'details',
      customRender: (group) => (
        <Button
          onClick={() => {
            setLevel('entry')
            setSortingGroup(group)
            resetSearch()
          }}
          size="small"
          circle
          variant="plain"
        >
          <Icons.Search />
        </Button>
      ),
      width: '11.4%',
    },
  ]

  const levelEntryStructure = [
    {
      key: 'barcodeBC',
      value: 'product.attributes.barcodeBC.value',
      label: __(T.misc.barcode_bc),
      width: '26%',
    },
    {
      key: 'description',
      value: 'product.description',
      label: __(T.misc.description),
      width: '25%',
    },
    {
      key: 'required_quantity',
      customRender: (entry) => <ModalLabel style={{ backgroundColor: '#ededed' }}>{entry.expectedQuantity}</ModalLabel>,
      label: __(T.misc.required_quantity),
      width: '19%',
    },
    {
      key: 'sorted_quantity',
      customRender: (entry) => (
        <ModalLabel
          style={{
            backgroundColor: entry.assignedQuantity < entry.expectedQuantity ? '#ff8474' : '#74ff92',
          }}
        >
          {entry.assignedQuantity}
        </ModalLabel>
      ),
      label: __(T.misc.sorted_quantity),
      width: '19%',
    },
    {
      key: 'details',
      customRender: (entry) => (
        <Button
          onClick={() => {
            setLevel('read')
            setSortingGroupEntry(entry)
            resetSearch()
          }}
          size="small"
          circle
          variant="plain"
        >
          <Icons.Search />
        </Button>
      ),
      width: '11.4%',
    },
  ]

  const levelReadStructure = [
    {
      key: 'serial',
      customValue: (read) => getSerial(read.item),
      label: __(T.misc.serial),
      width: '20%',
    },
    {
      key: 'epc',
      customValue: (read) => getEpc(read.item),
      label: 'EPC',
      width: '20%',
    },
    {
      key: 'zone',
      value: 'item.zone.description',
      label: __(T.misc.zone),
      width: '20%',
    },
    {
      key: 'sortingDate',
      customValue: (read) => format(new Date(read.creationDate), 'MM/dd/yyyy HH:mm:ss'),
      label: __(T.misc.sorted_date),
      width: '22%',
    },
    { key: 'user', value: 'creationUser.username', label: __(T.misc.user), width: '18.4%' },
  ]

  const closeModal = () => {
    setLevel('group')
    resetSearch()
    setIsOpen(false)
    setSortingGroups(undefined)
  }

  useEffect(() => {
    if (isOpen === true && prevIsOpen === false) {
      const fetch = async () => {
        setLoading(true)
        const newSortingGroups = (await SortingGroups.search<SortingGroup>({ size: 99999, sortingId })).content
        setSortingGroups(newSortingGroups)
        setLoading(false)
      }
      fetch()
    }
  }, [isOpen, prevIsOpen, sortingId])

  useEffect(() => {
    if (level === 'entry' && sortingGroup?.id) {
      const fetch = async () => {
        setLoading(true)
        setSortingGroupEntries(
          (await SortingGroupEntries.search<SortingGroupEntry>({ size: 99999, sortingGroupIds: [sortingGroup?.id] }))
            .content
        )
        setLoading(false)
      }
      fetch()
    }
  }, [level, sortingGroup?.id])

  useEffect(() => {
    if (level === 'read' && sortingGroupEntry?.id) {
      const fetch = async () => {
        setLoading(true)
        setSortingGroupReads(
          (
            await SortingGroupReads.search<SortingGroupRead>({
              size: 99999,
              sortingGroupEntryIds: [sortingGroupEntry?.id],
            })
          ).content
        )
        setLoading(false)
      }
      fetch()
    }
  }, [level, sortingGroupEntry?.id])

  sortingGroups?.sort((a, b) => a.priority - b.priority)
  sortingGroupEntries?.sort((a, b) =>
    a.assignedQuantity - a.expectedQuantity < b.assignedQuantity - b.expectedQuantity ? -1 : 1
  )

  const resetSearch = () => setSearch('')

  return (
    <>
      <Button title={__(T.misc.detail)} onClick={() => setIsOpen(true)} />
      <Modal visible={isOpen} onClose={closeModal} size="5xl" isCentered>
        <Box height="80vh">
          <ModalTitle mb="1em">
            {level === 'group' && __(T.titles.sorting_details)}
            {level === 'entry' && (
              <ModalBackButton
                toLevel="group"
                setLevel={setLevel}
                resetSearch={resetSearch}
                title={sortingGroup?.code}
              />
            )}
            {level === 'read' && (
              <ModalBackButton
                toLevel="entry"
                setLevel={setLevel}
                resetSearch={resetSearch}
                title={`${sortingGroupEntry?.product.description} - ${sortingGroupEntry?.product.attributes?.barcodeBC?.value}`}
              />
            )}
          </ModalTitle>
          {loading && (
            <Box flex center vcenter>
              <Spinner thickness="5px" speed="0.65s" color="#e0e0e0" size="xl" />
            </Box>
          )}
          {!loading && (
            <Box style={{ overflow: 'auto' }} flex>
              {level === 'group' && (
                <>
                  <Box width="50%" ml={5} mt={5} mb={15}>
                    <Input onChange={(value) => setSearch(value)} placeholder={__(T.misc.search)} startFocus />
                  </Box>
                  <Table
                    loading={!sortingGroups}
                    structure={levelGroupStructure}
                    data={sortingGroups?.filter((group) =>
                      search === '' ? true : group.code.toLowerCase().includes(search.toLowerCase())
                    )}
                  />
                </>
              )}
              {level === 'entry' && (
                <>
                  <Box width="50%" ml={5} mt={5} mb={15}>
                    <Input onChange={(value) => setSearch(value)} placeholder={__(T.misc.search)} startFocus />
                  </Box>
                  <Table
                    loading={!sortingGroupEntries}
                    structure={levelEntryStructure}
                    data={sortingGroupEntries?.filter((entry) =>
                      search === ''
                        ? true
                        : entry.product.attributes?.barcodeBC?.value?.toLowerCase().includes(search.toLowerCase())
                    )}
                  />
                </>
              )}
              {level === 'read' && (
                <>
                  <Box width="50%" ml={5} mt={5} mb={15}>
                    <Input onChange={(value) => setSearch(value)} placeholder={__(T.misc.search)} startFocus />
                  </Box>
                  <Table
                    loading={!sortingGroupReads}
                    structure={levelReadStructure}
                    data={sortingGroupReads?.filter((read) =>
                      search === '' ? true : getSerial(read.item).toLowerCase().includes(search.toLowerCase())
                    )}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </>
  )
}

const ModalBackButton: React.FC<{
  toLevel: ModalLevels
  setLevel: (level: ModalLevels) => void
  resetSearch: () => void
  title?: string
}> = ({ toLevel, setLevel, resetSearch, title }) => (
  <Box flex row>
    <Button
      onClick={() => {
        resetSearch()
        setLevel(toLevel)
      }}
      circle
      size="small"
    >
      <Icons.LeftArrow />
    </Button>
    <ModalTitle ml={10}>{title}</ModalTitle>
  </Box>
)

const Main = styled(Box)`
  background-color: ${({ theme }) => theme.background2};
  border-radius: 10px;
`
const ProductBox = styled(Box)<{ ok: boolean }>`
  background-color: ${({ ok }) => (ok ? '#75eba8' : '#ff8474')};
  border-radius: 10px;
`
const ProductTitle = styled(Box)<{ ok: boolean }>`
  font-weight: bold;
  font-size: ${({ ok }) => (ok ? 'clamp(36px, 5vw, 100px)' : '36px')};
  color: #333333;
  border-right: ${({ ok }) => (ok ? '1px' : '0')} solid #5dd691;
`
const ProductInfo = styled(Box)`
  border-bottom: 1px solid #5dd691;
  &:last-child {
    border: 0;
  }
  overflow: hidden;
`
const ProductInfoLabel = styled(Box)`
  font-size: 16px;
`
const ProductInfoValue = styled(Box)`
  font-size: clamp(16px, 2vw, 25px);
  font-weight: bold;
`
const Counter = styled(Box)`
  background-color: #fff;
  height: 184px;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 0px 8px 0px #00000026;
`
const CounterTitle = styled.div`
  font-weight: bold;
`
const CounterBody = styled(Box)`
  font-weight: bold;
  background-color: #e9e9e9;
  font-size: clamp(32px, 5vw, 80px);
  padding: 0 10px;
`
const IconCheck = styled.div`
  margin-right: 10px;
  svg * {
    fill: #75eba8;
  }
`
const IconMissing = styled.div`
  margin-right: 10px;
  svg * {
    fill: #ebbc75;
  }
`
const IconUnexpected = styled.div`
  margin-right: 10px;
  svg * {
    fill: #ff8474;
  }
`
const ModalTitle = styled(Box)`
  font-size: 26px;
  font-weight: bold;
`
const ModalLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  padding: 0.5rem 1rem;
  align-self: center;
`
