import { format } from 'date-fns'
import { get as _get, set as _set } from 'lodash'
import { StaticContext } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { api, BaseResource, ResourcePage, responseErrorCheck } from 'stylewhere/api'
import i18n, { T, __ } from 'stylewhere/i18n'
import { AppStore } from 'stylewhere/shared'
import { showToast, showToastError } from 'stylewhere/utils'

export type FormSchemaRouteProps<Props = {}, State = {}> = RouteComponentProps<
  Props,
  StaticContext,
  State & { formData?: FormSchemaData }
>

type Localizable = string | { en: string; it: string }

interface BaseSchemaDependency {
  is: string
  whenField: string // campo name
  condition: string
}

interface SchemaDependencySimple {
  is: 'disabled' | 'hidden' | 'enabled' | 'visible' | 'required' | 'optional' | 'emptied'
}

interface SchemaDependencyFilledWithValue {
  is: 'filledWithValue'
  filledWithValue: any
}

interface SchemaDependencyEndpointReplaced {
  is: 'endpointReplaced'
  endpointReplaced: { [key: string]: string }
}

interface SchemaDependencyIsEmptyFilled {
  condition: 'isEmpty' | 'isFilled'
  conditionValue?: never
}

interface SchemaDependencyHasValue {
  condition: 'hasValue'
  conditionValue: any
}

type AutocompleteSchemaDependency = BaseSchemaDependency &
  (SchemaDependencyIsEmptyFilled | SchemaDependencyHasValue) &
  (SchemaDependencySimple | SchemaDependencyFilledWithValue | SchemaDependencyEndpointReplaced)

type SchemaDependency =
  | (BaseSchemaDependency &
      (SchemaDependencyIsEmptyFilled | SchemaDependencyHasValue) &
      (SchemaDependencySimple | SchemaDependencyFilledWithValue))
  | AutocompleteSchemaDependency

export interface AutocompleteFormSchemaField extends Basefield {
  type: 'autocomplete'
  optionLabel?: 'description' | string
  optionSecondaryLabel?: 'code' | string
  endpoint: string
  /** Campo usato dalla dipendenza endpointReplaced */
  rawEndpoint?: string
  dependencies?: AutocompleteSchemaDependency[]
  /** Campo usato al posto di defaultValue quando la risorsa default deve essere caricata con un endpoint custom */
  defaultValueEndpoint?: string
  /** Usato in fetchAutocompleteDefaultValues come chiave di cache */
  defaultValueCacheKey?: string
  multiple?: boolean
}

export interface CheckboxFormSchemaField extends Basefield {
  type: 'checkbox'
  onValue?: string | number | boolean
  offValue?: string | number | boolean
}

export type DateFormSchemaField = Basefield & {
  type: 'date'
  time?: boolean
  mindate?: string
  maxdate?: string
} & (
    | {
        range: false
      }
    | {
        range: true
        startDatePath: string
        endDatePath: string
      }
  )

export interface NumberFormSchemaField extends Basefield {
  type: 'number'
  min?: number
  max?: number
  step?: number
  icon?: string
}

export interface TextFormSchemaField extends Basefield {
  type: 'text' | 'textarea' | 'password'
  minlength?: number
  maxlength?: number
  icon?: string
}

export interface SelectFormSchemaField extends Basefield {
  type: 'select'
  options: { label: string; value: string }[]
  multiple?: boolean
}

/**
 * I campi di tipo "value" hanno un valore fisso non modificabile (supporta i token).
 * Sono visualizzati nella form come campi di testo readonly
 */
export interface ValueFormSchemaField extends Basefield {
  type: 'value'
  defaultValue: string
}

interface Basefield {
  name: string
  label: string
  secondaryLabel?: string
  defaultValue?: any
  required?: boolean
  onChange?: (value: any, formData: any, setFormData: (newFormData: any) => void) => void
  hide?: boolean
  focus?: boolean
  disabled?: boolean
  readOnly?: boolean | 'start' | 'read'
  confirmValuePath?: string | false // False significa che il valore non deve essere inviato nella confirm
  dependencies?: SchemaDependency[]
  // L'opzione customRender è usabile solo via codice, ad esempio in una extension (formSchema):
  // myField.customRender = (props) => <MyComponent {...props} />
  customRender?: (props: any) => JSX.Element
}

export interface RawSchemaField {
  // Base
  type: 'autocomplete' | 'checkbox' | 'date' | 'number' | 'text' | 'textarea' | 'password' | 'select'
  name: string
  label: Localizable
  secondaryLabel?: Localizable
  defaultValue?: any
  required?: boolean
  onChange?: string
  hide?: boolean
  focus?: boolean
  disabled?: boolean
  readOnly?: boolean | 'start' | 'read'
  confirmValuePath?: string | false
  dependencies?: SchemaDependency[]
  // autocomplete
  optionLabel?: 'description' | string
  optionSecondaryLabel?: 'code' | string
  endpoint?: string
  defaultValueEndpoint?: string
  // select
  // Le opzioni delle select hanno due formati possibili:
  // - Value + label localizzabile: { label, value }[]
  // - Solo value: string[]
  options?: ({ label: Localizable; value: string | number | boolean } | string)[]
  multiple?: boolean
  // text
  minlength?: number
  maxlength?: number
  icon?: string
  // number
  min?: number
  max?: number
  step?: number
  // checkbox
  onValue?: string | number | true
  offValue?: string | number | false
  // date
  time?: boolean
  range?: boolean
  mindate?: string
  maxdate?: string
  startDatePath?: string
  endDatePath?: string
  visible?: boolean
  // value
  value?: any
}

export type FormSchemaField =
  | AutocompleteFormSchemaField
  | CheckboxFormSchemaField
  | DateFormSchemaField
  | NumberFormSchemaField
  | TextFormSchemaField
  | SelectFormSchemaField
  | ValueFormSchemaField
export type FormSchema = FormSchemaField[]
export type FormSchemaFieldData = string | boolean | number | undefined | Record<PropertyKey, string | boolean | number>
export type FormSchemaData<D extends Record<PropertyKey, FormSchemaFieldData> = any> = D

function translateLabel(label: Localizable): string {
  if (typeof label === 'string') {
    return label
  }
  if (typeof label[i18n.language] === undefined) {
    throw new Error(`Missing ${i18n.language} translation`)
  }
  return label[i18n.language]
}

const autocompleteDefaultValuesCache: Record<string, BaseResource | false> = {}

/** Converte i defaultValues dei campi autocomplete da stringhe di id a oggetti */
export async function fetchAutocompleteDefaultValues(schema: FormSchema) {
  const fields = schema.filter(
    (field): field is AutocompleteFormSchemaField =>
      field.type === 'autocomplete' && (!!field.defaultValueEndpoint || !!field.defaultValue)
  )

  for (const field of fields) {
    const { name, label, endpoint, defaultValue, defaultValueEndpoint } = field
    const cacheKey = field.defaultValueCacheKey ?? uuidv4()
    field.defaultValueCacheKey = cacheKey

    // if (autocompleteDefaultValuesCache[cacheKey]) continue

    //create data for search defaultValue
    let paramsSearch: any = {}
    if (typeof defaultValue === 'string' && defaultValue !== '') {
      if (Object.keys(DefaultArgsParams()).includes(defaultValue)) paramsSearch = { ids: setArgsParams(defaultValue) }
      else if (name.endsWith('Code')) paramsSearch = { equalCodes: setArgsParams(defaultValue) }
      else if (name.endsWith('Id')) paramsSearch = { ids: setArgsParams(defaultValue) }
    }

    let result

    try {
      // eslint-disable-next-line no-await-in-loop
      result = await api.get<ResourcePage<BaseResource>>(setArgsParams(defaultValueEndpoint ?? endpoint), paramsSearch)
      responseErrorCheck(result)
      // eslint-disable-next-line no-empty
    } catch (e) {
      showToastError(e)
    }

    if (result.ok) {
      if (result.data?.content[0]) {
        autocompleteDefaultValuesCache[cacheKey] = result.data.content[0]
      } else {
        const error = field.required && field.readOnly
        autocompleteDefaultValuesCache[cacheKey] = false
        showToast({
          title: __(error ? T.error.error : T.misc.warning),
          description: defaultValueEndpoint
            ? __(T.error.field_invalid_default_from_endpoint, {
                field: label,
              })
            : __(T.error.field_invalid_default, {
                defaultValue,
                field: label,
              }),
          status: error ? 'error' : 'warning',
        })
      }

      if (autocompleteDefaultValuesCache[cacheKey]) {
        field.defaultValue = autocompleteDefaultValuesCache[cacheKey]
      }
    }
  }

  return schema
}

export function jsonToFormSchema(raw: RawSchemaField[]): FormSchema {
  return raw.map((field) => {
    const baseField: Basefield = {
      name: field.name,
      label: translateLabel(field.label),
      secondaryLabel: field.secondaryLabel && translateLabel(field.secondaryLabel),
      required: field.required ?? true,
      defaultValue: field.defaultValue,
      hide: field.hide ?? false,
      focus: field.focus ?? false,
      disabled: field.disabled ?? false,
      readOnly: field.readOnly ?? false,
      // eslint-disable-next-line import/no-dynamic-require
      onChange: field.onChange && require(`stylewhere/config/${field.onChange}`),
      confirmValuePath: field.confirmValuePath,
      dependencies: field.dependencies,
    }

    let schemaField: FormSchemaField

    // By type
    if (field.type === 'autocomplete') {
      if (!field.endpoint) throw new Error('Missing option endpoint')
      schemaField = {
        type: 'autocomplete',
        optionLabel: field.optionLabel ?? 'description',
        optionSecondaryLabel: field.optionSecondaryLabel,
        endpoint: field.endpoint,
        defaultValueEndpoint: field.defaultValueEndpoint,
        multiple: field.multiple ?? false,
        ...baseField,
      }
    } else if (field.type === 'checkbox') {
      schemaField = {
        type: 'checkbox',
        onValue: field.onValue ?? true,
        offValue: field.offValue ?? false,
        ...baseField,
        required: field.required ?? false,
        defaultValue: field.defaultValue ?? false,
      }
    } else if (field.type === 'date') {
      if (field.range) {
        schemaField = {
          type: 'date',
          time: field.time ?? false,
          range: true,
          mindate: field.mindate,
          maxdate: field.maxdate,
          startDatePath: field.startDatePath ?? `${field.name}.from`,
          endDatePath: field.endDatePath ?? `${field.name}.to`,
          ...baseField,
        }
      } else {
        schemaField = {
          type: 'date',
          time: field.time ?? false,
          range: false,
          mindate: field.mindate,
          maxdate: field.maxdate,
          ...baseField,
        }
      }
    } else if (field.type === 'number') {
      schemaField = {
        type: 'number',
        min: field.min,
        max: field.max,
        step: field.step,
        icon: field.icon,
        ...baseField,
      }
    } else if (field.type === 'text' || field.type === 'textarea' || field.type === 'password') {
      schemaField = {
        type: field.type,
        minlength: field.minlength,
        maxlength: field.maxlength,
        icon: field.icon,
        ...baseField,
      }
    } else if (field.type === 'select') {
      if (field.options === undefined) {
        throw new Error('Missing option options')
      }
      schemaField = {
        type: 'select',
        options: field.options.map((option) =>
          typeof option === 'string'
            ? {
                label: option,
                value: option,
              }
            : {
                label: translateLabel(option.label),
                value: option.value.toString(),
              }
        ),
        multiple: field.multiple ?? false,
        ...baseField,
      }
    } else if (field.type === 'value') {
      schemaField = {
        type: 'value',
        defaultValue: field.defaultValue,
        ...baseField,
      }
    } else {
      throw new Error(`Unsupported field type ${field.type}`)
    }

    return schemaField
  })
}

export function getFieldValue(formData: FormSchemaData, field: FormSchemaField) {
  return _get(formData, field.name)
}

/** Imposta value in formData al percorso di field.name (con dot notation) */
export function setFieldValue(value: any, formData: FormSchemaData, field: FormSchemaField) {
  _set(formData, field.name, value)
}

export function formatFieldValue(formData: FormSchemaData, field: FormSchemaField): string | undefined {
  const value = getFieldValue(formData, field)

  if (value === null || value === undefined || value === '') return undefined

  if (field.type === 'date') {
    const formatDate = (date) => date && format(new Date(date), 'MM/dd/yyyy')
    if (field.range) {
      const startDate = formatDate(value[field.startDatePath])
      const endDate = formatDate(value[field.endDatePath])
      return startDate && endDate ? `${startDate} - ${endDate}` : `${startDate}${endDate}`
    }
    return formatDate(value)
  }

  if (field.type === 'autocomplete') {
    let label: string
    if (field.optionLabel) {
      label = value[field.optionLabel]
    } else if (field.optionSecondaryLabel) {
      label = value[field.optionSecondaryLabel]
    } else {
      label = ((value as any).description || (value as any).code || (value as any).id) ?? ''
    }
    return label
  }

  if (field.type === 'checkbox') {
    if (value === true) {
      return __(T.misc.yes)
    }
    if (value === false) {
      return __(T.misc.no)
    }
  }

  if (typeof value === 'number') return value.toString()
  if (typeof value === 'boolean') throw new Error('Invalid value')

  return value
}

export function getFieldConfirmValue(formData: FormSchemaData, field: FormSchemaField) {
  if (field.confirmValuePath === false) return undefined
  const object = getFieldValue(formData, field)
  if (field.confirmValuePath) return _get(object, field.confirmValuePath)
  return object
}

export function getDataFromSchema<DataType extends Record<PropertyKey, any>>(
  formData: FormSchemaData,
  formSchema: FormSchema
) {
  const data: DataType = JSON.parse(JSON.stringify(formData))
  Object.values(formSchema ?? {}).forEach((fs) => _set(data, fs.name, getFieldConfirmValue(formData, fs)))
  return data
}

/** Token per gli endpoint e i defaultValues. Possono essere stringhe o oggetti  */
const DefaultArgsParams = () => ({
  /** Sostituisce il place della postazione attiva */
  '{placeId}': AppStore?.defaultWorkstation?.placeId ?? '',
})

/** Sostituisce i token in un endpoint con i valori di DefaultArgsParams  */
export function setArgsParams(endpoint: string) {
  const defaultArgsParams = DefaultArgsParams()
  Object.keys(defaultArgsParams).forEach((key) => {
    endpoint = endpoint.replaceAll(key, defaultArgsParams[key])
  })
  return endpoint
}

export function checkRequiredField(formData: FormSchemaData, formSchema: FormSchema): boolean {
  try {
    let checked = true
    Object.values(formSchema ?? {})
      .filter((fs) => fs.required)
      .forEach((fs) => {
        if (getFieldConfirmValue(formData, fs) === undefined) checked = false
      })
    return checked
  } catch (error) {
    return false
  }
}
