import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Image, Icons } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { showToast } from 'stylewhere/utils'
import { AppStore, Router, OperationConfig, RemoteOperation } from 'stylewhere/shared'
import { Routes } from 'stylewhere/pages'

type ButtonProps = {
  code: string
  operation: Routes
  routeParams?: any
  icon?: React.ReactNode
  text?: string
  displayPriority?: number
}

export const DashboardOperationsButtons: React.FC = () => {
  const [enabled, setEnabled] = useState<OperationConfig[]>()

  useEffect(() => {
    const fetch = async () => {
      await RemoteOperation.load()
      setEnabled(RemoteOperation.operations.filter((op) => op.enabled))
    }
    fetch()
  }, [])

  if (enabled === undefined) {
    return (
      <>
        {[...Array(6)].map((e, i) => (
          <FakeButton key={i} />
        ))}
      </>
    )
  }

  const staticOperations: ButtonProps[] = [
    {
      code: 'tag-info',
      operation: '/tag-info',
      icon: <Icons.TagInfo />,
      text: 'Tag Info',
      displayPriority: 1,
    },
  ]

  const transferToZone: ButtonProps[] = enabled
    .filter((op) => op.type === 'TRANSFER_TO_ZONE')
    .map((op) => ({
      code: op.code,
      operation: '/transfer-to-zone/:opCode',
      icon: <Icons.TransferToZone />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
    }))

  const simpleItemList: ButtonProps[] = enabled
    .filter((op) => op.type === 'SIMPLE_ITEM_LIST')
    .map((op) => ({
      code: op.code,
      operation: '/simple-item-list/:opCode',
      icon: <Icons.SimpleItemList />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
    }))

  const simpleParcelList: ButtonProps[] = enabled
    .filter((op) => op.type === 'SIMPLE_PARCEL_LIST')
    .map((op) => ({
      code: op.code,
      operation: '/simple-parcel-list/:opCode',
      icon: <Icons.SimpleParcelList />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
    }))

  const shippingItemList: ButtonProps[] = enabled
    .filter((op) => op.type === 'INBOUND' || op.type === 'OUTBOUND' || op.type === 'PACK')
    .map((op) => {
      let icon
      if (op.type === 'INBOUND') icon = <Icons.Inbound />
      if (op.type === 'OUTBOUND') icon = <Icons.Outbound />
      return {
        code: op.code,
        operation: '/shipping/:opCode',
        icon: icon,
        routeParams: { opCode: op.code },
        text: `${op.description}`,
        displayPriority: op.displayPriority ?? 0,
      }
    })

  const sorting: ButtonProps[] = enabled
    .filter((op) => op.type === 'SORTING')
    .map((op) => ({
      code: op.code,
      operation: '/sorting/:opCode',
      icon: <Icons.SimpleParcelList />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
    }))

  const encoding: ButtonProps[] = enabled
    .filter((op) => op.type === 'ENCODING')
    .map((op) => ({
      code: op.code,
      operation: '/encoding/:opCode',
      icon: <Icons.Encoding />,
      routeParams: { opCode: op.code },
      text: `${op.description}`,
      displayPriority: op.displayPriority ?? 0,
    }))

  const buttons: ButtonProps[] = [
    ...staticOperations,
    ...transferToZone,
    ...simpleItemList,
    ...simpleParcelList,
    ...shippingItemList,
    ...sorting,
    ...encoding,
  ].sort((a, b) => {
    if ((a.displayPriority ?? 0) > (b.displayPriority ?? 0)) {
      return -1
    }
    if ((a.displayPriority ?? 0) > (b.displayPriority ?? 0)) {
      return 1
    }
    return 0
  })

  const onClick = (route, routeParams) => {
    if (!AppStore.defaultWorkstation) {
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      if (!AppStore.emulation) return
    }
    route && Router.navigate(route, routeParams)
  }

  return (
    <>
      {buttons.map(({ operation: route, routeParams, icon, text, code }) => (
        <Button key={code} onClick={() => onClick(route, routeParams)}>
          {icon && typeof icon !== 'string' && icon}
          {icon && typeof icon === 'string' && <Icon alt={text} src={icon} />}
          {text}
        </Button>
      ))}
      {buttons.length % 2 === 1 && <FakeButton />}
    </>
  )
}

const Button = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  width: 276px;
  height: 202px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 #00000014;
  border-radius: 10px;
  margin: 0 20px 30px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`

const FakeButton = styled.div`
  width: 276px;
  height: 202px;
  background: #ffffff;
  border: 2px dashed #eeeeee;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 20px 30px;
`

const Icon = styled(Image)`
  margin-bottom: 10px;
  max-height: 100px;
  max-width: 100px;
`
