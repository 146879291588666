import { PureComponent } from 'react'
import { Box, BoxProps, EmptyListMessage, Icons } from 'stylewhere/components'
import { List as VirtualizedList, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import { __, T } from 'stylewhere/shared/i18n'
import ResizeObserver from 'rc-resize-observer'
import styled from '@emotion/styled'

interface Props {
  data?: any[]
  emptyMessage?: string | JSX.Element
  rowRender: (item: any, index: number) => JSX.Element
  loading?: boolean
  title?: string
}

export interface RowProps extends BoxProps {
  item?: any
  structure?: any
}

export interface CellProps extends BoxProps {
  label?: any
  value?: any
}
export class List extends PureComponent<Props> {
  static Row = (props: RowProps) => {
    const { children, ...rest } = props
    return (
      <Row row {...rest}>
        {children}
      </Row>
    )
  }

  static Cell = ({ label, value, children, flex = 1, ...rest }: CellProps) => {
    return (
      <Box flex={flex} justify={'center'} {...rest}>
        {label && <CellLabel>{label}</CellLabel>}
        {!!(value ?? children) && <CellValue>{value ?? children}</CellValue>}
      </Box>
    )
  }

  _listRef: VirtualizedList
  cache = new CellMeasurerCache({
    fixedWidth: true,
  })

  static defaultProps: Partial<Props> = {
    emptyMessage: <span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.list_empty)}</span>,
  }

  _getRowHeight = ({ index }) => {
    return this.cache.rowHeight({ index })
  }

  rowRender = ({ index, parent, key, style }) => {
    return (
      <CellMeasurer key={key} cache={this.cache} parent={parent} columnIndex={0} rowIndex={index}>
        {({ measure }) => (
          <div style={style}>
            <ResizeObserver onResize={measure}>{this.props.rowRender(this.props.data?.[index], index)}</ResizeObserver>
          </div>
        )}
      </CellMeasurer>
    )
  }

  render() {
    const { data, emptyMessage, title, loading } = this.props
    if (loading) {
      return (
        <ListContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Icons.Loader1 />
        </ListContainer>
      )
    }
    if (!data || data.length === 0) {
      return (
        <EmptyListMessage style={{ backgroundColor: 'white', borderRadius: 15 }} center>
          {emptyMessage}
        </EmptyListMessage>
      )
    }
    return (
      <>
        {!!title && <Title>{title}</Title>}
        <ListContainer>
          <AutoSizer>
            {({ height, width }) => (
              <VirtualizedList
                tabIndex={null}
                ref={(r) => (this._listRef = r!)}
                width={width}
                rowHeight={this._getRowHeight}
                height={height}
                rowCount={data.length}
                rowRenderer={this.rowRender}
                overscanIndicesGetter={({ cellCount, stopIndex }) => {
                  const overScanBottom = stopIndex + 5
                  return {
                    overscanStartIndex: 0,
                    overscanStopIndex: (overScanBottom > cellCount ? cellCount : overScanBottom) - 1,
                  }
                }}
              />
            )}
          </AutoSizer>
        </ListContainer>
      </>
    )
  }
}

const ListContainer = styled.div`
  padding: 1px;
  flex: 1 1 auto;
  min-height: 40px;
`
const Title = styled.p`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
`

const Row = styled(Box)`
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  padding: 32px 50px;
  margin: 5px;
  justify-content: space-between;
`

const CellLabel = styled(Box)`
  font-weight: bold;
  font-size: 16px;
`

const CellValue = styled(Box)`
  font-size: 20px;
`
