import { Component } from 'react'
import { Page, TagCounter, AntennaButton, Box, GroupedItemList } from 'stylewhere/components'
import { DecodedItem, Items } from 'stylewhere/api'
import { RfidReader } from 'stylewhere/shared'

interface State {
  items: DecodedItem<string>[]
}

export default class TagInfo extends Component<{}, State> {
  state: State = {
    items: [],
  }

  componentDidMount() {
    RfidReader.setDecodeFunction(Items.batchDecode)
    RfidReader.setOnDecodedItemCallback(this.onItemDecoded)
  }

  onItemDecoded = (decodedItems: { [epc: string]: DecodedItem<string> }) => {
    const { items } = this.state
    Object.keys(decodedItems).forEach((key) => {
      const decodedItem = decodedItems[key]
      items.push({
        item: decodedItem as any,
        identifierCode: key,
      })
    })
    //console.log(items)
    this.setState({ items })
  }

  clear = () => this.setState({ items: [] })

  render() {
    const { items } = this.state
    return (
      <Page title="TagInfo" enableEmulation>
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={items.length} />
            <AntennaButton onClear={this.clear} hideClear={items.length === 0} />
          </Box>
        </Page.Sidebar>

        <Page.Content notBoxed>
          <GroupedItemList rows={items} />
        </Page.Content>
      </Page>
    )
  }
}
