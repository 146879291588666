import { Component } from 'react'
import { DecodedItem, GroupedItemsByProduct } from 'stylewhere/api'
import { GroupedProductRow, List } from 'stylewhere/components'
import { OperationConfig } from 'stylewhere/shared'
import { __, T } from 'stylewhere/shared/i18n'

interface Props<OC extends OperationConfig> {
  rows: GroupedItemsByProduct[]
  emptyMessage?: string | JSX.Element
  loading?: boolean
  onRemoveItem?: (item: DecodedItem) => void
  onActionItem?: (item: DecodedItem) => void
  operation: OC
}

export class GroupedProductList extends Component<Props<any>> {
  rowRender = (item) => {
    const { operation, onRemoveItem, onActionItem } = this.props
    const { product, detected, expected, unexpected, items, status } = item
    return (
      <GroupedProductRow
        key={product.code}
        product={product}
        detected={detected}
        expected={expected}
        unexpected={unexpected}
        //TODO Prendere nochecklist da operation
        operation={operation}
        decodedItems={items}
        onRemoveItem={onRemoveItem}
        onActionItem={onActionItem}
        status={status}
      />
    )
  }

  render() {
    const { rows } = this.props
    return (
      <List
        data={rows.sort((a: GroupedItemsByProduct, b: GroupedItemsByProduct) => {
          if (a.status === 'error') return -1
          if (b.status === 'error') return 1
          if (a.status === 'warning') return -1
          if (b.status === 'warning') return 1
          if (a.status === 'ignored') return -1
          if (b.status === 'ignored') return 1
          return 0
        })}
        emptyMessage={<span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.press_start_to_read)}</span>}
        rowRender={this.rowRender}
      />
    )
  }
}
