import { AppStore } from 'stylewhere/shared'
import config from 'stylewhere/config'
import { api, Product, responseErrorCheck, TmrProduct } from 'stylewhere/api'
import Images from './Images'

export class Products {
  static endpoint = 'tracking/api/v1/products'

  static get(code: string): any {
    return api
      .get<any[]>(`${this.endpoint}?equalCodes=${code}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static async getProductsByCodes(upcs: string[]): Promise<Product[]> {
    return api
      .post<Product | any>(`${this.endpoint}/advancedSearch`, {
        codes: upcs,
        limit: 50000,
      })
      .then(responseErrorCheck)
  }

  static getImageID = (record: any) => {
    let imageId = ''
    if (record) {
      if (record.attributes) {
        if (record.attributes.pictureId) {
          imageId = record.attributes.pictureId.value || ''
        } else if (record.attributes.sketchId) {
          imageId = record.attributes.sketchId.value || ''
        }
      } else if (record._ImageId) {
        imageId = record._ImageId
      } else if (record._SkatchId) {
        imageId = record._SkatchId
      }
      if (imageId === '') {
        if (record.code) {
          imageId = `IMG-${record.code}`
        } else if (record.productCode) {
          imageId = `IMG-${record.productCode}`
        }
      }
    }
    return imageId
  }

  static getImageUrl(product?: any) {
    if (!product || !AppStore.authToken) {
      return ''
    }
    const imageId = this.getImageID(product)

    let url = ''
    if (imageId && imageId !== '') {
      const baseURL =
        !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? config.endpoint : config.endpoint
      url = `${baseURL}${Images.endpoint}?imageId=${imageId}&access_token=${AppStore.authToken}`
    }
    return url
  }
}
