import { Component } from 'react'
import { Sorting } from 'stylewhere/api'
import { List, RowProps, StatusChip } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { getDatetime } from 'stylewhere/shared/utils'

interface Props extends RowProps {
  sorting: Sorting
  onClick: () => void
}

export class SortingRow extends Component<Props> {
  render() {
    const { sorting, onClick, ...rest } = this.props
    return (
      <List.Row onClick={onClick} {...rest}>
        <List.Cell label={__(T.misc.code)} value={sorting.code} />
        <List.Cell flex={0.5} label={__(T.misc.status)}>
          <StatusChip status={sorting.status} />
        </List.Cell>
        <List.Cell flex={1.5} label={__(T.misc.description)} value={sorting.description} />
        <List.Cell label={__(T.misc.creation_date)} value={getDatetime(new Date(sorting.creationDate).getTime())} />
      </List.Row>
    )
  }
}
